<h1 mat-dialog-title>Xác nhận xóa</h1>
<div mat-dialog-content>
  <p>Bạn sẽ xóa danh mục/menu này: <br><span style="color: crimson; font-weight: 500">"{{data?.name}}"?</span></p>
  <mat-label> </mat-label>
  <mat-label></mat-label>
</div>
<div mat-dialog-actions class="pl-15">
  <button mat-button (click)="onNoClick()" cdkFocusInitial>Không xóa</button>
  <button mat-button [mat-dialog-close]="data.id" (click)="onDelete()">Đồng ý xóa</button>
</div>
