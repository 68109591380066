<form [formGroup]="userForm" (ngSubmit)="onSaveNewUser()">
  <h2>Thêm User</h2>
  <mat-form-field appearance="outline">
    <mat-label>Tên</mat-label>
    <input matInput type="text" formControlName="userName">
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Email</mat-label>
    <input matInput type="email" email required formControlName="email">
    <mat-error *ngIf="userForm?.controls?.email?.invalid">Email phải đúng định dạng</mat-error>
<!--    <mat-error *ngIf="email.errors?.required">Email là bắt buộc</mat-error>-->
  </mat-form-field>
  <div>
    <mat-form-field appearance="outline">
      <mat-label>Mật khẩu</mat-label>
      <input matInput type="password" required formControlName="password">
      <mat-error *ngIf="userForm?.controls?.password?.invalid">Mật khẩu là bắt buộc, lớn hơn 6 ký tự</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Xác nhận Mật khẩu</mat-label>
      <input matInput type="password" required formControlName="rePassword">
      <mat-error *ngIf="userForm?.controls?.rePassword?.value !== userForm?.controls?.password?.value">Mật khẩu xác nhận chưa đúng</mat-error>
    </mat-form-field>
  </div>
  <mat-form-field>
    <mat-label>Role</mat-label>
    <mat-select required formControlName="role">
      <mat-option class="mat-option" *ngFor="let role of roles" [value]="role.id">{{role.name}}</mat-option>
    </mat-select>
  </mat-form-field>
<!--  <mat-error *ngIf="userForm?.controls?.role?.errors?.required">-->
<!--    Yêu cầu nhập-->
<!--  </mat-error>-->
  <mat-checkbox class="example-margin" formControlName="isEnabled">Trạng thái Active
  </mat-checkbox>
  <br>
  <br>
  <div class="button">
    <button type="submit" mat-button color="primary">Thêm</button>
  </div>
</form>
