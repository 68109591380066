import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Path} from '../common/path';
import 'rxjs/add/operator/map';
import {PostHome} from '../models/response/post-home';

@Injectable()
export class HomeService {

  constructor(private http: HttpClient) {
  }


}
