<h2>Thêm Danh mục/Menu</h2>
<mat-form-field appearance="outline">
  <mat-label>Tên</mat-label>
  <input matInput type="text" [(ngModel)]="categoryName">
</mat-form-field>
<mat-form-field>
  <mat-label>Template</mat-label>
  <mat-select  (selectionChange)="changingCategoryValue($event)">
    <mat-option class="mat-option" *ngFor="let tem of templates" [value]="tem">Template {{tem}}</mat-option>
  </mat-select>
</mat-form-field>
<mat-checkbox (change)="changingShowInMenu($event)">Cho phép hiển thị ở Menu</mat-checkbox>
<mat-checkbox (change)="changingShowInHomepage($event)">Cho phép hiển thị ở Trang chủ</mat-checkbox>
<br>
<br>
<div class="button">
  <button type="submit" mat-button color="primary" (click)="onSaveNewUser()">Thêm</button>
</div>
