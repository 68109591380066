import {Component, OnInit} from '@angular/core';
import {Role} from '../../../shared/models/response/role';
import {User} from '../../../shared/models/response/user';
import {UserService} from '../../../shared/services/user.service';
import {ToastrService} from 'ngx-toastr';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-user-create-dialog',
  templateUrl: './user-create-dialog.component.html',
  styleUrls: ['./user-create-dialog.component.css']
})
export class UserCreateDialogComponent implements OnInit {
  isEnabled: boolean;
  roles: Role[];
  data: User;
  modules: any;
  // userTypes = ['USER', 'CREATOR', 'EDITOR', 'ADMIN'];
  userForm: FormGroup;

  constructor(private userService: UserService,
              private toastr: ToastrService,
              private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    this.getRoles();
    this.initForm();
  }

  initForm(): void {
    this.userForm = this.formBuilder.group({
      userName: [''],
      email: ['', [
        Validators.required,
        Validators.maxLength(200),
        Validators.minLength(6),
      ]],
      password: ['',
        [Validators.required,
        Validators.maxLength(200),
        Validators.minLength(6)],
      ],
      rePassword: ['',
        [Validators.required,
        Validators.maxLength(200),
        Validators.minLength(6)],
      ],
      isEnabled: [false],
      role: [Validators.required]
    });
  }

  onSaveNewUser(): void {
    if (this.userForm.invalid) {
      return;
    }
    const userFormValue = this.userForm.value;
    const user = new User();
    const role = new Role();
    role.id = userFormValue.role;
    user.userName = userFormValue.userName;
    user.newPwd = userFormValue.password;
    user.email = userFormValue.email;
    user.role = role;
    user.isEnabled = userFormValue.isEnabled;
    if (role.id > 0) {
    } else {
      this.toastr.error('Role là bắt buộc');
      return;
    }

    this.saveUser(user);
  }

  saveUser(user: User): void {
    this.userService.saveUser(user).subscribe((result) => {
      if (result) {
        this.toastr.success('Thêm mới User thành công!', null, {timeOut: 12000});
      }
    }, (e) => {
      console.log(e);
    });
  }

  getRoles() {
    this.userService.getRoles().subscribe((result) => {
      if (result) {
        this.roles = result.data;
      }
    }, (e) => {
      console.log(e);
    });
  }

}
