import {Component, Inject, OnInit} from '@angular/core';
import {CommentService} from '../../../shared/services/comment.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {UserService} from '../../../shared/services/user.service';

@Component({
  selector: 'app-user-delete-dialog',
  templateUrl: './user-delete-dialog.component.html',
  styleUrls: ['./user-delete-dialog.component.css']
})
export class UserDeleteDialogComponent implements OnInit {

  constructor(
    private userService: UserService,
    public dialogRef: MatDialogRef<UserDeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public id: number,
    private router: Router) {
  }

  ngOnInit(): void {
  }

  onDelete(): void {
    this.userService.delete(this.id).subscribe(
      (result) => {
        this.reload();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  reload(): void {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigateByUrl('/user');
    });
  }
}
