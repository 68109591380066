import {Component, OnInit} from '@angular/core';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {Category} from '../../shared/models/response/category';
import {CategoryService} from '../../shared/services/category.service';
import {MatDialog} from '@angular/material/dialog';
import {CategoryDeleteDialogComponent} from './category-delete-dialog/category-delete-dialog.component';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import {CategoryCreateDialogComponent} from './category-create-dialog/category-create-dialog.component';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements OnInit {
  categories: Category[];
  templates = [1, 2, 3, 4, 5];

  constructor(private categoryService: CategoryService,
              public dialog: MatDialog,
              private toastr: ToastrService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.getCategory();
  }


  drop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.categories, event.previousIndex, event.currentIndex);
  }

  getCategory(): void {
    this.categoryService.getCategoryAll().subscribe((result) => {
      if (result) {
        this.categories = result;
      }
    }, (e) => {
      console.log(e);
    });
  }

  onSaveCategory(): void {
    this.categories.forEach((cat, index) => {
      cat.order = index + 1;
    });

    this.saveCategory(this.categories);
  }

  saveCategory(requests: Category[]): void {
    this.categoryService.saveCategory(requests).subscribe(
      (result) => {
        this.toastr.success('Cập nhật thành công');
        this.reload();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  changingCategoryName(event, id): void {
    this.categories.forEach(cat => {
      if (cat.id === id) {
        cat.name = event.target.value;
      }
    });
  }

  changingCategoryValue(event, id): void {
    this.categories.forEach(cat => {
      if (cat.id === id) {
        cat.templateId = event.value;
      }
    });
  }

  changingShowInMenu(event, id): void {
    this.categories.forEach(cat => {
      if (cat.id === id) {
        cat.showInMenu = event.checked;
      }
    });
  }

  changingShowInHomepage(event, id): void {
    this.categories.forEach(cat => {
      if (cat.id === id) {
        cat.showInHomepage = event.checked;
      }
    });
  }


  onDelete(category: Category): void {
    this.dialog.open(CategoryDeleteDialogComponent, {
      width: '300px',
      data: {
        id: category.id,
        name: category.name
      },
    });
  }

  onNewCategory(): void {
    this.dialog.open(CategoryCreateDialogComponent, {
      width: '400px',
      data: {},
    });
  }

  reload(): void {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigateByUrl('/category');
    });
  }
}
