<h3 class="text-center">Chi tiết</h3>
<mat-card class="mb-20">
  <mat-label>Câu hỏi từ <b
    style="color: #df6e0a">{{data.anonymousName}}</b>, {{data.createAt * 1000 | date: 'd/M/yyyy HH:mm'}}</mat-label>
  <hr>
  <mat-card-content>
    <mat-label style="font-weight: bold">{{data?.title}}</mat-label>
    <br>
    <mat-label class="multi_lines_text">{{data?.content}}</mat-label>
  </mat-card-content>
</mat-card>
<mat-card *ngIf="data?.isAdminReply">
  <mat-label>Trả lời từ <b style="color: #df6e0a">Bảo vệ chính pháp</b>
    , {{(data?.isAdminReply ? (data.answers[0].createAt * 1000 | date: 'd/M/yyyy HH:mm') : '')}}</mat-label>
  <hr>
  <mat-card-content>
    <mat-label [innerHTML]="data?.isAdminReply ? data?.answers[0]?.content : ''"></mat-label>
  </mat-card-content>
</mat-card>

