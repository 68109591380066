import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Path} from '../common/path';
import {Setting} from '../models/response/setting';
import 'rxjs-compat/add/operator/map';
import {Result} from '../models/response/result';

@Injectable({
  providedIn: 'root'
})
export class SettingService {
  constructor(private http: HttpClient) {
  }

  getSettings(): Observable<Setting[]> {
    return this.http.get<any>(Path.SETTINGS).map(
      result => {
        return result;
      }, error => {
        return error;
      }
    );
  }

  saveSettings(request: Setting[]): Observable<Result<any>> {
    return this.http.post(Path.SETTING, request).map((result: Result<any>) => {
      return result;
    }, error => {
      return error;
    });
  }
}
