import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {SearchService} from '../../../shared/services/search.service';
import {CommentRequest} from '../../../shared/models/request/CommentRequest';
import {Comment} from '../../../shared/models/response/comment';
import {CommentService} from '../../../shared/services/comment.service';
import {CommentDeleteDialogComponent} from '../comment-delete-dialog/comment-delete-dialog.component';
import {CommentDetailDialogComponent} from '../comment-detail-dialog/comment-detail-dialog.component';
import {CommentSaveDialogComponent} from '../comment-save/comment-save-dialog.component';

@Component({
  selector: 'app-comment-list',
  templateUrl: './comment-list.component.html',
  styleUrls: ['./comment-list.component.css']
})
export class CommentListComponent implements OnInit {
  isApproved: boolean;
  request: CommentRequest;
  limit: number;
  page: number;
  displayedColumns: string[] = ['id', 'content', 'post_title', 'parentComment', 'parentId', 'user_name', 'is_approved', 'create_at', 'update_at', 'edit', 'delete'];
  dataSource: MatTableDataSource<Comment>;
  pageSize: number;
  pageIndex: number;
  orderBy: string;
  length: number;
  pageSizeOptions: number[] = [10, 25, 100];
  keyword: string;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private commentService: CommentService,
              private router: Router,
              public dialog: MatDialog,
              private toastr: ToastrService,
              private searchService: SearchService,
              private changeDetectorRefs: ChangeDetectorRef,
              private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.request = {
      limit: 10,
      page: 0
    };
    this.getComment(this.request);
  }

  getComment(request: CommentRequest): void {
    this.commentService.getComment(request).subscribe(
      (result) => {
        this.length = result.metadata ? result.metadata.total : 100;
        this.dataSource = new MatTableDataSource(result.data);
        this.changeDetectorRefs.detectChanges();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  onPageChange(pageEvent): void {
    this.pageSize = pageEvent.pageSize;
    this.pageIndex = pageEvent.pageSize;
    this.request = {
      keyword: this.keyword,
      isApproved: (this.isApproved === false || this.isApproved === true) ? this.isApproved : null,
      limit: pageEvent.pageSize,
      page: pageEvent.pageIndex,
      orderby: this.orderBy ? this.orderBy : 'create_at desc'
    };
    this.getComment(this.request);
  }

  onClickFilterApproved(isApproved): void {
    if (isApproved.value === 'all') {
      this.isApproved = null;
    } else {
      this.isApproved = (isApproved.value === 'true');
    }
    this.request = {
      keyword: this.keyword,
      isApproved: this.isApproved,
      limit: 10,
      page: 0,
      orderby: this.orderBy ? this.orderBy : 'create_at desc'
    };
    this.getComment(this.request);
  }

  sortData(sortEvent): void {
    if (sortEvent.direction === '') {
      this.orderBy = 'create_at desc';
    } else {
      this.orderBy = sortEvent.active + ' ' + sortEvent.direction;
    }
    this.request = {
      keyword: this.keyword,
      isApproved: this.isApproved,
      limit: this.paginator.pageSize,
      page: this.paginator.pageIndex,
      orderby: this.orderBy
    };
    this.getComment(this.request);
  }

  onSearch(): void {
    this.request = {
      keyword: this.keyword,
      isApproved: this.isApproved,
      limit: 10,
      page: 0,
      orderby: this.orderBy ? this.orderBy : 'create_at desc'
    };
    this.getComment(this.request);
  }

  onOpenCommentDetail(comment: Comment): void {
    this.dialog.open(CommentDetailDialogComponent, {
      width: '800px',
      height: '70vh',
      data: comment
    });
  }

  onEdit(id): void {
    this.dialog.open(CommentSaveDialogComponent, {
      width: '800px',
      height: '70vh',
      data: id
    });
  }

  onDelete(id): void {
    this.dialog.open(CommentDeleteDialogComponent, {
      width: '300px',
      data: id
    });
  }

  reload(): void {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigateByUrl('/comment-list');
    });
  }
}
