<h3 class="text-center">Chi tiết</h3>
<mat-card class="mb-20">
  <mat-label style="font-weight: bold">Bài viết: {{data?.postTitle}}</mat-label>
  <hr>
  <mat-card-content>
    <mat-label>Comment từ <b
      style="color: #df6e0a">{{data.userName}}</b>, {{data.createAt * 1000 | date: 'd/M/yyyy HH:mm'}}</mat-label>
    <br>
    <br>
    <mat-label style="font-weight: bold">Nội dung: </mat-label>
    <mat-label class="multi_lines_text">{{data?.content}}</mat-label>
  </mat-card-content>
</mat-card>

