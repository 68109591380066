<mat-card>
  <mat-card-content>
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
      <h2>Log In</h2>
      <mat-error *ngIf="error">
        Email hoặc password chưa đúng!
      </mat-error>
      <mat-form-field class="full-width-input">
        <input matInput placeholder="Email" formControlName="email" required>
        <mat-error>
          Vui lòng nhập email hợp lệ
        </mat-error>
      </mat-form-field>
      <mat-form-field class="full-width-input">
        <input matInput type="password" placeholder="Password" formControlName="password" required>
        <mat-error>
          Vui lòng nhập password hợp lệ
        </mat-error>
      </mat-form-field>
      <button mat-raised-button color="primary">Login</button>
    </form>
  </mat-card-content>
</mat-card>
