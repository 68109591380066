import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Result} from '../models/response/result';
import {Path} from '../common/path';
import {CommentRequest} from '../models/request/CommentRequest';
import {Comment} from '../models/response/comment';
import 'rxjs/add/operator/map';

@Injectable({
  providedIn: 'root'
})
export class CommentService {

  constructor(private http: HttpClient) {
  }

  getComment(request: CommentRequest): Observable<Result<Comment[]>> {
    return this.http.get<any>(Path.COMMENT + '?limit=' + request.limit +
      '&page=' + request.page + (request.keyword ? ('&keyword=' + request.keyword) : '') +
      (request.orderby ? ('&orderby=' + request.orderby) : '') +
      (request.isApproved != null ? ('&isApproved=' + request.isApproved) : '')
      , {}).map(
      result => {
        return result;
      }, error => {
        return error;
      }
    );
  }

  getCommentDetail(id: number): Observable<Result<Comment>> {
    return this.http.get<any>(Path.API_BACKEND + '/comment/' + id, {}).map(
      result => {
        return result;
      }, error => {
        return error;
      }
    );
  }

  saveComment(request: Comment): Observable<Result<any>> {
    return this.http.post(Path.SAVE_COMMENT, request).map((result: Result<any>) => {
      return result;
    }, error => {
      return error;
    });
  }

  delete(id: number): Observable<Result<any>> {
    return this.http.post(Path.DELETE_COMMENT + '/' + id, id).map((result: Result<any>) => {
      return result;
    }, error => {
      return error;
    });
  }

  getCommentUnApprove(): Observable<Result<any>> {
    return this.http.get<any>(Path.COMMENT_UN_APPROVE).map(
      result => {
        return result;
      }, error => {
        return error;
      }
    );
  }
}
