import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Comment} from '../../../shared/models/response/comment';

@Component({
  selector: 'app-comment-detail-dialog',
  templateUrl: './comment-detail-dialog.component.html',
  styleUrls: ['./comment-detail-dialog.component.css']
})
export class CommentDetailDialogComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: Comment) {
  }

  ngOnInit(): void {
  }

}
