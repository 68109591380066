import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {QaService} from '../../../shared/services/qa.service';
import {Qa} from '../../../shared/models/response/qa';
import {ToastrService} from 'ngx-toastr';
import {Answer} from '../../../shared/models/response/answer';
import {ActivatedRoute} from '@angular/router';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-send-question',
  templateUrl: './qa-save.component.html',
  styleUrls: ['./qa-save.component.css']
})
export class QaSaveComponent implements OnInit {
  questionForm = new FormGroup({
    title: new FormControl(''),
    content: new FormControl(''),
    anonymousName: new FormControl(''),
    isApproved: new FormControl(false),
  });
  answerForm = new FormGroup({
    contentAnswer: new FormControl('')
  });
  data: Qa;
  // id: number;
  @Input() error: string | null;

  @Output() submitEM = new EventEmitter();

  modules: any;
  contentAnswer: string;

  constructor(private qaService: QaService,
              private toastr: ToastrService,
              private activatedRoute: ActivatedRoute,
              @Inject(MAT_DIALOG_DATA) public id: number) {
  }

  ngOnInit(): void {
    this.toolbarEditorInit();
    // this.id = Number(this.activatedRoute.snapshot.paramMap.get('id'));
    this.getQa(this.id);
  }

  toolbarEditorInit(): void {
    this.modules = {
      toolbar: {
        container: [
          ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
          ['blockquote', 'code-block'],
          [{list: 'ordered'}, {list: 'bullet'}],
          [{script: 'sub'}, {script: 'super'}],      // superscript/subscript
          [{size: ['small', false, 'large', 'huge']}],  // custom dropdown
          [{color: new Array<any>()}, {background: new Array<any>()}],          // dropdown with defaults from theme
          [{font: new Array<any>()}],
          [{align: new Array<any>()}],
          ['clean'],                                         // remove formatting button
          ['link']                         // link and image, video
        ]
      },
      imageResize: {},
      videoResize: {}
    };
  }

  getQa(id: number): void {
    this.qaService.getQaDetail(id).subscribe(
      (result) => {
        if (result) {
          this.data = result.data;
          this.questionForm.controls.title.setValue(this.data.title);
          this.questionForm.controls.content.setValue(this.data.content);
          this.questionForm.controls.anonymousName.setValue(this.data.anonymousName);
          this.questionForm.controls.isApproved.setValue(this.data.isApproved);

          this.contentAnswer = this.data.answers.length > 0 ? (this.data.answers[0].content) : '';
        }
      },
      (error) => {
        // console.log(error);
      }
    );
  }

  onSaveQuestion(): void {
    if (this.questionForm.invalid) {
      return;
    }
    // TODO: Use EventEmitter with form value
    const questionFormValue = this.questionForm.value;
    const saveQuestion = new Qa();
    saveQuestion.id = this.id;
    saveQuestion.title = questionFormValue.title;
    saveQuestion.content = questionFormValue.content;
    saveQuestion.anonymousName = questionFormValue.anonymousName;
    saveQuestion.isApproved = questionFormValue.isApproved;
    this.saveQuestion(saveQuestion);
  }

  saveQuestion(question: Qa): void {
    this.qaService.saveQuestion(question).subscribe((result) => {
      if (result) {
        this.toastr.success('Cập nhật câu hỏi thành công!', null, {timeOut: 12000});
      }
    }, (e) => {
      console.log(e);
    });
  }

  onSaveAnswer(): void {
    if (this.answerForm.invalid) {
      return;
    }
    // TODO: Use EventEmitter with form value
    const answerFormValue = this.answerForm.value;
    const saveAnswer = new Answer();
    saveAnswer.id = this.data.answers.length > 0 ? this.data.answers[0].id : null;
    saveAnswer.questionId = this.id;
    saveAnswer.content = this.contentAnswer;
    this.saveAnswer(saveAnswer);
  }

  saveAnswer(answer: Answer): void {
    if (!this.contentAnswer || this.contentAnswer == '') {
      this.toastr.warning('Nhập nội dung câu trả lời!', null, {timeOut: 12000});
      return;
    }
    this.qaService.saveAnswer(answer).subscribe((result) => {
      if (result) {
        this.toastr.success('Cập nhật câu trả lời thành công!', null, {timeOut: 12000});
      }
    }, (e) => {
      console.log(e);
    });
  }
}
