<mat-card>
  <mat-card-title>Bình luận</mat-card-title>
  <mat-card-content>
    <!--    <mat-label>Câu hỏi từ <b-->
    <!--      style="color: #df6e0a">{{data.userName}}</b>, {{data.createAt * 1000 | date: 'd/M/yyyy HH:mm'}}</mat-label>-->
    <form [formGroup]="commentForm" (ngSubmit)="onSaveComment()">
      <mat-form-field appearance="fill">
        <mat-label>Nội dung</mat-label>
        <textarea required matInput placeholder="" matTextareaAutosize matAutosizeMinRows="15" matAutosizeMaxRows="4"
                  formControlName="content" maxlength="20000"></textarea>
        <mat-error *ngIf="commentForm.controls.content.hasError('required')">
          Bạn cần nhập vào nội dung bình luận
        </mat-error>
      </mat-form-field>

      <p *ngIf="error" class="error">
        {{ error }}
      </p>

      <mat-checkbox class="example-margin" formControlName="isApproved">Trạng thái phê duyệt</mat-checkbox>
      <div class="button">
        <button type="submit" mat-button color="primary">Cập nhật</button>
      </div>
    </form>
  </mat-card-content>
</mat-card>
<br>
