<h1 mat-dialog-title>Xác nhận xóa</h1>
<div mat-dialog-content>
  <p>Bạn sẽ xóa câu hỏi này và câu trả lời liên quan ID <span style="color: crimson">{{id}}?</span></p>
  <mat-label> </mat-label>
  <mat-label></mat-label>
</div>
<div mat-dialog-actions class="pl-15">
  <button mat-button (click)="onNoClick()" cdkFocusInitial>Không xóa</button>
  <button mat-button [mat-dialog-close]="id" (click)="onDelete()">Đồng ý xóa</button>
</div>
