<h2>Quản lý hỏi đáp</h2>
<div class="container">
  <mat-form-field class="mb-20 full-width">
    <mat-label>Tìm kiếm</mat-label>
    <input type="text" matInput placeholder="" [(ngModel)]="keyword" (ngModelChange)="onSearch()"
           maxlength="200">
  </mat-form-field>
  <br>
  <mat-label>Lọc:</mat-label>
  <button style="border-radius: 0px; margin-left: 10px" mat-button class="pointer"
          [ngClass]="this.repliedFilter == null ? 'active' : ''"
          (click)="onClickFilterAll()">Toàn bộ
  </button>
  <button style="border-radius: 0px" mat-button class="pointer" [ngClass]="this.repliedFilter == true ? 'active' : ''"
          (click)="onClickFilterTrue()"> Đã trả lời
  </button>
  <button style="border-radius: 0px" mat-button class="pointer" [ngClass]="this.repliedFilter == false ? 'active' : ''"
          (click)="onClickFilterFalse()"> Chưa trả lời
  </button>
  <mat-form-field class="ml-10">
    <mat-label>Trạng thái phê duyệt</mat-label>
    <mat-select (selectionChange)="onClickFilterApproved($event)" style="font-size: 14px; font-weight: 500">
      <mat-option value="all">Tất cả</mat-option>
      <mat-option value="true">Đã phê duyệt</mat-option>
      <mat-option value="false">Chưa phê duyệt</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-label style="float: right;padding: 22px 1px 0 0" >
    <span style="cursor:pointer;color: #4c2525; text-decoration: none" (click)="reload()">
      <mat-icon>refresh</mat-icon>
    </span>
  </mat-label>
  <div class="mat-elevation-z8 mt-10">
    <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)">

      <ng-container matColumnDef="icon">
        <th mat-header-cell *matHeaderCellDef width="6%"> </th>
        <td mat-cell *matCellDef="let row">
          <mat-icon *ngIf="row?.isAdminReply" style="color:#18aa41">check_circle_outline</mat-icon>
          <mat-icon *ngIf="!row?.isAdminReply" style="color:#df6e0a;">help_outline</mat-icon>
        </td>
      </ng-container>

      <!-- ID Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header width="4%"> ID</th>
        <td mat-cell *matCellDef="let row">{{row.id}}</td>
      </ng-container>


      <!-- title Column -->
      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef mat-sort-header width="60%"> Tiêu đề</th>
        <td mat-cell *matCellDef="let row"><a style="text-decoration: none;  cursor: pointer; font-weight: 500"
                                              class="pointer" (click)="onOpenQaDetail(row)">{{row.title}}</a></td>
      </ng-container>

      <!-- isApproved Column -->
      <ng-container matColumnDef="is_approved">
        <th mat-header-cell *matHeaderCellDef mat-sort-header width="10%"> Phê duyệt</th>
        <td mat-cell *matCellDef="let row">
          <mat-icon *ngIf="row.isApproved">done</mat-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="create_at">
        <th mat-header-cell *matHeaderCellDef mat-sort-header width="10%"> Ngày tạo</th>
        <td mat-cell *matCellDef="let row"> {{row.createAt * 1000 | date: 'd/M/yyyy HH:mm'}} </td>
      </ng-container>

      <ng-container matColumnDef="update_at">
        <th mat-header-cell *matHeaderCellDef mat-sort-header width="10%"> &nbsp;&nbsp;Ngày cập nhật</th>
        <td mat-cell *matCellDef="let row">
          &nbsp;&nbsp;{{row.updateAt * 1000 | date: 'd/M/yyyy &nbsp;&nbsp;HH:mm'}} </td>
      </ng-container>

      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef width="7%">Cập nhật</th>
        <td mat-cell *matCellDef="let row">
          <mat-icon color="primary" class="pointer" (click)="onEdit(row.id)">edit</mat-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef width="7%">Xóa</th>
        <td mat-cell *matCellDef="let row">
          <mat-icon color="warn" class="pointer" (click)="onDelete(row.id)">delete</mat-icon>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">Không có dữ liệu</td>
      </tr>
    </table>

    <mat-paginator [length]="length"
                   [pageSize]="pageSize"
                   [pageSizeOptions]="pageSizeOptions"
                   (page)="onPageChange($event)">
    </mat-paginator>
  </div>
  <br>
  <br>
</div>
