import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Path} from '../common/path';
import 'rxjs/add/operator/map';
import {SearchRequest} from '../models/request/search-request';
import {Post} from '../models/response/post';
import {Result} from '../models/response/result';

@Injectable()
export class SearchService {

  constructor(private http: HttpClient) {
  }

  getSearch(searchRequest: SearchRequest): Observable<Result<Post[]>> {
    return this.http.get<any>(Path.SEARCH + '?keyword=' + searchRequest.keyword + '&limit=' + searchRequest.limit +
      '&offset=' + searchRequest.offset, {}).map(
      result => {
        return result;
      }, error => {
        return error;
      }
    );
  }
}
