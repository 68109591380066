<form [formGroup]="userForm" (ngSubmit)="onSaveComment()">
  <h2>Sửa User</h2>
  <mat-form-field appearance="outline">
    <mat-label>Tên</mat-label>
    <input matInput type="text" formControlName="userName">
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Email</mat-label>
    <input matInput type="text" email formControlName="email">
  </mat-form-field>
  <mat-checkbox class="example-margin" (click)="onOpenChangePassword()">Đổi mật khẩu</mat-checkbox>
  <br><br>
  <div [hidden]="!isChangePassword">
    <mat-form-field appearance="outline">
      <mat-label>Mật khẩu mới</mat-label>
      <input matInput type="password" formControlName="password">
      <mat-error *ngIf="userForm?.controls?.password?.invalid">Mật khẩu là bắt buộc, lớn hơn 6 ký tự</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Xác nhận Mật khẩu mới</mat-label>
      <input matInput type="password" formControlName="rePassword">
      <mat-error *ngIf="userForm?.controls?.rePassword?.value !== userForm?.controls?.password?.value">Mật khẩu xác nhận chưa đúng</mat-error>
    </mat-form-field>
  </div>
  <mat-form-field>
    <mat-label>Role</mat-label>
    <mat-select (selectionChange)="changingRoleValue($event)" formControlName="role">
      <mat-option class="mat-option" *ngFor="let role of roles" [value]="role.id">{{role.name}}</mat-option>
    </mat-select>
  </mat-form-field>
<!--  <mat-error *ngIf="userForm?.controls?.role?.errors?.required">-->
<!--    Yêu cầu nhập-->
<!--  </mat-error>-->
  <mat-checkbox class="example-margin" [checked]="user?.isEnabled" formControlName="isEnabled">Trạng thái Active
  </mat-checkbox>
  <br>
  <br>
  <div class="button">
    <button type="submit" mat-button color="primary">Cập nhật</button>
  </div>
</form>
