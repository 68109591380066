import {CategorySub} from './categorySub';

export class Category {
  id: number;
  name: string;
  description: string;
  templateId: number;
  type: string;
  friendlyUrl?: string;
  order?: number;
  showInHomepage?: boolean;
  showInMenu?: boolean;
  categorySubs: CategorySub[];
}
