import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {MediaService} from '../../../shared/services/media.service';

@Component({
  selector: 'app-media-delete-dialog',
  templateUrl: './media-delete-dialog.component.html',
  styleUrls: ['./media-delete-dialog.component.css']
})
export class MediaDeleteDialogComponent implements OnInit {

  constructor(
    private mediaService: MediaService,
    public dialogRef: MatDialogRef<MediaDeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public id: number,
    private router: Router) {
  }

  ngOnInit(): void {
  }

  onDelete(): void {
    this.mediaService.delete(this.id).subscribe(
      (result) => {
        location.reload(true);
        // handle refresh
      },
      (error) => {
        console.log(error);
      }
    );
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
