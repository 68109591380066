<h2>Quản lý User</h2>
<div class="container">
  <mat-form-field class="mb-20 full-width">
    <mat-label>Tìm kiếm</mat-label>
    <input type="text" matInput placeholder="" [(ngModel)]="keyword" (ngModelChange)="onSearch()"
           maxlength="200">
  </mat-form-field>
  <br>
  <mat-form-field class="ml-10">
    <mat-label>Loại tài khoản user</mat-label>
    <mat-select (selectionChange)="onClickFilterProvider($event)" style="font-size: 14px; font-weight: 500">
      <mat-option value="all">Tất cả</mat-option>
      <mat-option value="local">Nội bộ</mat-option>
      <mat-option value="facebook">Facebook</mat-option>
      <mat-option value="google">Google</mat-option>
    </mat-select>
  </mat-form-field>

  &nbsp;&nbsp;&nbsp;&nbsp;<button type="submit" mat-raised-button color="primary" (click)="openCreateUserDialog()">Thêm mới</button>
  <mat-label style="float: right;padding: 22px 1px 0 0" >
    <span style="cursor:pointer;color: #4c2525; text-decoration: none" (click)="reload()">
      <mat-icon>refresh</mat-icon>
    </span>
  </mat-label>
  <div class="mat-elevation-z8 mt-10">
    <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)">

      <!-- ID Column -->
      <ng-container matColumnDef="create_at">
        <th mat-header-cell *matHeaderCellDef mat-sort-header width="4%"> ID</th>
        <td mat-cell *matCellDef="let row">{{row.id}}</td>
      </ng-container>

      <!-- user name Column -->
      <ng-container matColumnDef="user_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header width="20%"> Tên</th>
        <td mat-cell *matCellDef="let row">{{row?.userName}}</td>
      </ng-container>

      <!-- email Column -->
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header width="25%"> Email</th>
        <td mat-cell *matCellDef="let row">{{row?.email}}</td>
      </ng-container>

      <!-- Provider Column -->
      <ng-container matColumnDef="provider">
        <th mat-header-cell *matHeaderCellDef mat-sort-header width="10%"> Loại user</th>
        <td mat-cell *matCellDef="let row" >{{row?.provider == 'local' ? 'Nội bộ' : (row?.provider) | titlecase}}</td>
      </ng-container>

      <!-- Role Column -->
      <ng-container matColumnDef="role">
        <th mat-header-cell *matHeaderCellDef width="15%"> Role</th>
        <td mat-cell *matCellDef="let row">{{row?.role?.name}}</td>
      </ng-container>

      <!-- isEnabled Column -->
      <ng-container matColumnDef="is_enabled">
        <th mat-header-cell *matHeaderCellDef mat-sort-header mat-sort-header width="10%"> Active</th>
        <td mat-cell *matCellDef="let row">
          <mat-icon *ngIf="row.isEnabled">done</mat-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef width="7%">Cập nhật</th>
        <td mat-cell *matCellDef="let row">
          <mat-icon color="primary" class="pointer" (click)="onEdit(row)">edit</mat-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef width="7%">Xóa</th>
        <td mat-cell *matCellDef="let row">
          <mat-icon color="warn" class="pointer" (click)="onDelete(row.id)">delete</mat-icon>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">Không có dữ liệu</td>
      </tr>
    </table>

    <mat-paginator [length]="length"
                   [pageSize]="pageSize"
                   [pageSizeOptions]="pageSizeOptions"
                   (page)="onPageChange($event)">
    </mat-paginator>
  </div>
  <br>
  <br>
</div>
