import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {User} from '../models/response/user';
import {environment} from '../../../environments/environment';
import {BehaviorSubject, Observable} from 'rxjs';
import {Path} from '../common/path';
import {Result} from '../models/response/result';
import 'rxjs/add/operator/map';
import {UserRequest} from '../models/request/user-request';
import {Role} from '../models/response/role';

@Injectable({providedIn: 'root'})
export class UserService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(private http: HttpClient) {
    // this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    // this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  getUserFromToken(token: string): Observable<User> {
    return this.http.get<any>(Path.HOST + '/auth/oauth2/redirect?token=' + token, {}).map(
      result => {
        return result;
      }, error => {
        return error;
      }
    );
  }

  getUserCurrent(): Observable<User> {
    return this.http.get<User>(`${environment.HOST}/user/me`);
  }

  getUsers(request: UserRequest): Observable<Result<User[]>> {
    return this.http.get<any>(Path.USERS + '?limit=' + request.limit +
      '&page=' + request.page + (request.keyword ? ('&keyword=' + request.keyword) : '') +
      (request.orderby ? ('&orderby=' + request.orderby) : '') +
      (request.provider != null ? ('&provider=' + request.provider) : '')
      , {}).map(
      result => {
        return result;
      }, error => {
        return error;
      }
    );
  }

  saveUser(request: User): Observable<Result<any>> {
    return this.http.post(Path.SAVE_USER, request).map((result: Result<any>) => {
      return result;
    }, error => {
      return error;
    });
  }

  delete(id: number): Observable<Result<any>> {
    return this.http.post(Path.DELETE_USER + '/' + id, id).map((result: Result<any>) => {
      return result;
    }, error => {
      return error;
    });
  }

  getRoles(): Observable<Result<Role[]>> {
    return this.http.get<any>(Path.ROLES, {}).map(
      result => {
        return result;
      }, error => {
        return error;
      }
    );
  }
}
