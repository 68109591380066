import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {SearchService} from '../../shared/services/search.service';
import {UserSaveDialogComponent} from './user-save-dialog/user-save-dialog.component';
import {UserDeleteDialogComponent} from './user-delete-dialog/user-delete-dialog.component';
import {UserRequest} from '../../shared/models/request/user-request';
import {UserService} from '../../shared/services/user.service';
import {User} from '../../shared/models/response/user';
import {UserCreateDialogComponent} from './user-create-dialog/user-create-dialog.component';
import {ProviderType} from '../../shared/models/enum/provider-type';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {
  provider: ProviderType;
  request: UserRequest;
  limit: number;
  page: number;
  displayedColumns: string[] = ['create_at', 'user_name', 'email', 'provider', 'role', 'is_enabled', 'edit', 'delete'];
  dataSource: MatTableDataSource<User>;
  pageSize: number;
  pageIndex: number;
  orderBy: string;
  length: number;
  pageSizeOptions: number[] = [10, 25, 100];
  keyword: string;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private userService: UserService,
              private router: Router,
              public dialog: MatDialog,
              private toastr: ToastrService,
              private searchService: SearchService,
              private changeDetectorRefs: ChangeDetectorRef,
              private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.request = {
      limit: 10,
      page: 0,
      orderby: this.orderBy ? this.orderBy : 'create_at desc'
    };
    this.getUsers(this.request);
  }

  getUsers(request: UserRequest): void {
    this.userService.getUsers(request).subscribe(
      (result) => {
        this.length = result.metadata ? result.metadata.total : 100;
        this.dataSource = new MatTableDataSource(result.data);
        this.changeDetectorRefs.detectChanges();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  onPageChange(pageEvent): void {
    this.pageSize = pageEvent.pageSize;
    this.pageIndex = pageEvent.pageSize;
    this.request = {
      keyword: this.keyword,
      provider: this.provider ? this.provider : null,
      limit: pageEvent.pageSize,
      page: pageEvent.pageIndex,
      orderby: this.orderBy ? this.orderBy : 'create_at desc'
    };
    this.getUsers(this.request);
  }

  onClickFilterProvider(provider): void {
    if (provider.value === 'all') {
      this.provider = null;
    } else {
      this.provider = provider.value;
    }
    this.request = {
      keyword: this.keyword,
      provider: this.provider,
      limit: 10,
      page: 0,
      orderby: this.orderBy ? this.orderBy : 'create_at desc'
    };
    this.getUsers(this.request);
  }

  sortData(sortEvent): void {
    if (sortEvent.direction === '') {
      this.orderBy = 'create_at desc';
    } else {
      this.orderBy = sortEvent.active + ' ' + sortEvent.direction;
    }
    this.request = {
      keyword: this.keyword,
      provider: this.provider,
      limit: this.paginator.pageSize,
      page: this.paginator.pageIndex,
      orderby: this.orderBy
    };
    this.getUsers(this.request);
  }

  onSearch(): void {
    this.request = {
      keyword: this.keyword,
      provider: this.provider,
      limit: 10,
      page: 0,
      orderby: this.orderBy ? this.orderBy : 'create_at desc'
    };
    this.getUsers(this.request);
  }

  onEdit(user): void {
    this.dialog.open(UserSaveDialogComponent, {
      width: '800px',
      data: user
    });
  }

  onDelete(id): void {
    this.dialog.open(UserDeleteDialogComponent, {
      width: '300px',
      data: id
    });
  }

  reload(): void {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigateByUrl('/user');
    });
  }

  openCreateUserDialog(): void {
    this.dialog.open(UserCreateDialogComponent, {
      width: '800px'
    });
  }

  public get providerType(): typeof ProviderType {
    return ProviderType;
  }
}
