<h2>Quản lý ảnh</h2>
<div class="container">
  <mat-form-field class="mb-20 full-width">
    <mat-label>Tìm kiếm</mat-label>
    <input type="text" matInput placeholder="" [(ngModel)]="keyword" (ngModelChange)="onSearch()"
           maxlength="200">
  </mat-form-field>
  <br>
  <div>
    Upload image: <input type="file" (change)="uploadImage($event.target.files)"/>
    <img style="max-width: 300px; max-height: 55px;margin-top: 8px" src="{{imageUrl}}">
  </div>
  <div class="mat-elevation-z8 mt-10">
    <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)">

      <!-- ID Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef width="4%"> ID</th>
        <td mat-cell *matCellDef="let row">{{row.id}}</td>
      </ng-container>

      <ng-container matColumnDef="thumbnail">
        <th mat-header-cell *matHeaderCellDef width="20%"> Thumbnail</th>
        <td mat-cell *matCellDef="let row"><a target="_blank" href="{{host + row?.imagePath}}"><img style="max-width: 200px; max-height: 60px" src="{{host + row?.imagePath}}"></a></td>
      </ng-container>

      <ng-container matColumnDef="originalName">
        <th mat-header-cell *matHeaderCellDef width="15%"> Tên</th>
        <td mat-cell *matCellDef="let row">{{row?.originalName}}</td>
      </ng-container>

      <ng-container matColumnDef="parentFolder">
        <th mat-header-cell *matHeaderCellDef width="10%"> Thư mục</th>
        <td mat-cell *matCellDef="let row">{{row?.parentFolder}}</td>
      </ng-container>

      <ng-container matColumnDef="imagePath">
        <th mat-header-cell *matHeaderCellDef width="20%"> URL</th>
        <td mat-cell *matCellDef="let row">{{host + row?.imagePath}}</td>
      </ng-container>

      <ng-container matColumnDef="copy">
        <th mat-header-cell *matHeaderCellDef width="7%">Copy URL</th>
        <td mat-cell *matCellDef="let row">
          <mat-icon color="primary" class="pointer" (click)="copyUrl(host + row?.imagePath)">content_copy</mat-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef width="7%">Xóa</th>
        <td mat-cell *matCellDef="let row">
          <mat-icon color="warn" class="pointer" (click)="onDelete(row.id)">delete</mat-icon>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">Không có dữ liệu</td>
      </tr>
    </table>

    <mat-paginator [length]="length"
                   [pageSize]="pageSize"
                   [pageSizeOptions]="pageSizeOptions"
                   (page)="onPageChange($event)">
    </mat-paginator>
  </div>
  <br>
  <br>
</div>
