import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {CommentService} from '../../../shared/services/comment.service';

@Component({
  selector: 'app-comment-delete-dialog',
  templateUrl: './comment-delete-dialog.component.html',
  styleUrls: ['./comment-delete-dialog.component.css']
})
export class CommentDeleteDialogComponent implements OnInit {

  constructor(
    private commentService: CommentService,
    public dialogRef: MatDialogRef<CommentDeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public id: number,
    private router: Router) {
  }

  ngOnInit(): void {
  }

  onDelete(): void {
    this.commentService.delete(this.id).subscribe(
      (result) => {
        // this.router.navigate(['/comment-list']);
        location.reload(true);
        // handle refresh
      },
      (error) => {
        console.log(error);
      }
    );
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
