import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Path} from '../common/path';
import {PostRequest} from '../models/request/post-request';
import {Result} from '../models/response/result';
import {Post} from '../models/response/post';

@Injectable()
export class PostService {
  constructor(private http: HttpClient) {
  }

  savePost(request: PostRequest): Observable<Result<any>> {
    return this.http.post(Path.SAVE_POST, request).map((result: Result<any>) => {
      return result;
    }, error => {
      return error;
    });
  }

  getPostDetail(id: number): Observable<Post> {
    return this.http.get<any>(Path.POST + '/get/' + id + '/admin', {}).map(
      result => {
        return result;
      }, error => {
        return error;
      }
    );
  }

  delete(id: number): Observable<Result<any>> {
    return this.http.post(Path.DELETE_POST + '/' + id, id).map((result: Result<any>) => {
      return result;
    }, error => {
      return error;
    });
  }

  getTag(): Observable<Result<string[]>> {
    return this.http.get<any>(Path.API_BACKEND + '/tag/all').map(
      result => {
        return result;
      }, error => {
        return error;
      }
    );
  }

}
