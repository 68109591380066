<h2>Danh sách bài viết</h2>
<mat-form-field class="mb-20 full-width">
  <mat-label>Tìm kiếm</mat-label>
  <input type="text" matInput placeholder="" [(ngModel)]="keyword" (ngModelChange)="onSearch()"
         maxlength="200">
</mat-form-field>
<br>
<mat-form-field>
  <mat-label>Thể loại</mat-label>
  <select matNativeControl [(ngModel)]="categoryIdDefault" (ngModelChange)="onSelectCategoryChange($event)">
    <option value="0">Tất cả</option>
    <option *ngFor="let cate of categories" value="{{cate?.id}}">{{cate?.name}}</option>
  </select>
</mat-form-field>
&nbsp;&nbsp;&nbsp;&nbsp;<button type="submit" mat-raised-button color="primary" (click)="openCreatePost()">Thêm bài mới</button>
<mat-label style="float: right;padding: 22px 1px 0 0" >
    <span style="cursor:pointer;color: #4c2525; text-decoration: none" (click)="reload()">
      <mat-icon>refresh</mat-icon>
    </span>
</mat-label>

<div class="mat-elevation-z8">
  <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)" style="width: -webkit-fill-available;">

    <!-- ID Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header width="7%"> ID</th>
      <td mat-cell *matCellDef="let row"> {{row.id}} </td>
    </ng-container>

    <!-- title Column -->
    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef mat-sort-header width="20%"> Tiêu đề</th>
      <td mat-cell *matCellDef="let row"> <a style="text-decoration: none" href="{{webHost}}/{{row.friendlyUrl}}?from=admin">{{row.title}}</a> </td>
    </ng-container>

    <!-- category Column -->
    <ng-container matColumnDef="category_name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header width="10%"> Thể loại</th>
      <td mat-cell *matCellDef="let row">{{row.categoryName}} </td>
    </ng-container>

    <!-- category Column -->
    <ng-container matColumnDef="category_sub_name">
      <th mat-header-cell *matHeaderCellDef width="10%"> Thể loại con</th>
      <td mat-cell *matCellDef="let row">{{row.categorySubName}} </td>
    </ng-container>

    <!-- author Column -->
    <ng-container matColumnDef="author_name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header width="8%"> Tác giả</th>
      <td mat-cell *matCellDef="let row"> {{row.authorName}} </td>
    </ng-container>

    <!-- commentCount Column -->
    <ng-container matColumnDef="commentCount">
      <th mat-header-cell *matHeaderCellDef>Bình luận</th>
      <td mat-cell *matCellDef="let row"> {{row.commentCount}} </td>
    </ng-container>

    <!-- viewCounts Column -->
    <ng-container matColumnDef="view_counts">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>View</th>
      <td mat-cell *matCellDef="let row"> {{row.viewCounts}} </td>
    </ng-container>

    <ng-container matColumnDef="is_feature">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Tin nổi bật</th>
      <td mat-cell *matCellDef="let row">
        <mat-icon *ngIf="row.isFeature">done</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="is_publish">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Publish</th>
      <td mat-cell *matCellDef="let row">
        <mat-icon *ngIf="row.isPublish">done</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="post_type">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Kiểu</th>
      <td mat-cell *matCellDef="let row"> {{row.postType}} </td>
    </ng-container>

    <ng-container matColumnDef="create_at">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Ngày tạo</th>
      <td mat-cell *matCellDef="let row"> {{row.createAt * 1000 | date: 'd/M/yyyy HH:mm'}} </td>
    </ng-container>

    <ng-container matColumnDef="publishDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Ngày publish</th>
      <td mat-cell *matCellDef="let row"> <ng-container *ngIf="row.publishDate">{{row.publishDate * 1000 | date: 'd/M/yyyy HH:mm'}}</ng-container> </td>
    </ng-container>

    <ng-container matColumnDef="update_at">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> &nbsp;&nbsp;Ngày sửa</th>
      <td mat-cell *matCellDef="let row"> &nbsp;&nbsp;{{row.updateAt * 1000 | date: 'd/M/yyyy &nbsp;&nbsp;HH:mm'}} </td>
    </ng-container>

    <ng-container matColumnDef="edit">
      <th mat-header-cell *matHeaderCellDef> &nbsp;&nbsp;&nbsp;&nbsp;Sửa&nbsp;&nbsp;&nbsp;&nbsp;</th>
      <td mat-cell *matCellDef="let row"> <mat-icon color="primary" class="pointer" (click)="onEdit(row.id)">&nbsp;&nbsp;edit</mat-icon></td>
    </ng-container>

    <ng-container matColumnDef="delete">
      <th mat-header-cell *matHeaderCellDef> &nbsp;&nbsp;&nbsp;Xóa</th>
      <td mat-cell *matCellDef="let row"> <mat-icon color="warn" class="pointer" (click)="onDelete(row.id)">&nbsp;delete</mat-icon></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">Không có dữ liệu</td>
    </tr>
  </table>

  <mat-paginator [length]="length"
                 [pageSize]="pageSize"
                 [pageSizeOptions]="pageSizeOptions"
                 (page)="onPageChange($event)">
  </mat-paginator>
</div>
<br>
<br>
