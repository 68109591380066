export class Comment {
  id: number;
  content: string;
  postId: number;
  postTitle: string;
  userId: number;
  createAt: number;
  updateAt: number;
  isApproved: boolean;
  userAvatar?: any;
  userName?: any;
  parentComment?: string;
  parentId?: number;
}
