import {Component, OnInit} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {Category} from '../../../shared/models/response/category';
import {CategoryService} from '../../../shared/services/category.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-category-create-dialog',
  templateUrl: './category-create-dialog.component.html',
  styleUrls: ['./category-create-dialog.component.css']
})
export class CategoryCreateDialogComponent implements OnInit {
  categoryName: string;
  templateId: number;
  modules: any;
  templates = [1, 2, 3, 4, 5];
  showInHomepage: boolean;
  showInMenu: boolean;

  constructor(private categoryService: CategoryService,
              private toastr: ToastrService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.categoryName = '';
  }

  changingCategoryValue(event): void {
    this.templateId = event.value;
  }

  changingShowInMenu(event): void {
    this.showInMenu = event.checked;
  }

  changingShowInHomepage(event): void {
    this.showInHomepage = event.checked;
  }

  saveCategory(requests: Category[]): void {
    this.categoryService.saveCategory(requests).subscribe(
      (result) => {
        this.toastr.success('Thêm mới danh mục/menu thành công');
        this.reload();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  onSaveNewUser(): void {
    if (this.categoryName.length === 0) {
      this.toastr.warning('Tên danh mục/menu là bắt buộc');
      return;
    }
    const requests = [];
    const request = new Category();
    request.name = this.categoryName;
    request.templateId = this.templateId;
    request.showInMenu = this.showInMenu;
    request.showInHomepage = this.showInHomepage;
    requests.push(request);

    this.saveCategory(requests);
  }

  reload(): void {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigateByUrl('/category');
    });
  }
}
