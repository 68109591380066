<h2>Quản lý Danh mục/Menu</h2>
<div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
  <mat-grid-list cols="12" rowHeight="60px" class="move-box" style="background-color: rgb(0 0 0 / 6%)">
    <mat-grid-tile colspan="1"><b>Sắp xếp</b></mat-grid-tile>
    <mat-grid-tile colspan="4"><b>Tên Danh mục/Menu</b></mat-grid-tile>
    <mat-grid-tile colspan="2"><b>Kiểu</b></mat-grid-tile>
    <mat-grid-tile colspan="2"><b>Template</b></mat-grid-tile>
    <mat-grid-tile colspan="1" style="text-align: center;"><b>Hiển thị menu</b></mat-grid-tile>
    <mat-grid-tile colspan="1" style="text-align: center;"><b>Hiển thị trang chủ</b></mat-grid-tile>
    <mat-grid-tile colspan="1"><b>Xóa</b></mat-grid-tile>
  </mat-grid-list>
  <mat-grid-list cols="12" rowHeight="60px" *ngFor="let category of categories" cdkDrag class="move-box" style="cursor: move">
    <mat-grid-tile colspan="1">
      <mat-icon>drag_handle</mat-icon>
    </mat-grid-tile>
    <mat-grid-tile colspan="4">
      <mat-form-field style="min-width: 100px">
        <input required matInput [value]="category?.name" maxlength="200" (change)="changingCategoryName($event, category.id)">
        <mat-error *ngIf="!category?.name">
          Cần nhập vào menu
        </mat-error>
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile colspan="2">{{category?.type == 'POST' ? 'Bài Post' : 'Hỏi Đáp'}}</mat-grid-tile>
    <mat-grid-tile colspan="2">
      <mat-form-field style="text-align: center; width: 100px; min-width: 70px">
        <mat-select [value]="category?.templateId" (selectionChange)="changingCategoryValue($event, category?.id)">
          <mat-option class="mat-option" *ngFor="let tem of templates" [value]="tem">Template {{tem}}</mat-option>
        </mat-select>
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile colspan="1">
      <mat-checkbox [checked] = "category?.showInMenu" (change)="changingShowInMenu($event, category?.id)"></mat-checkbox>
    </mat-grid-tile>
    <mat-grid-tile colspan="1">
      <mat-checkbox [checked] = "category?.showInHomepage" (change)="changingShowInHomepage($event, category?.id)"></mat-checkbox>
    </mat-grid-tile>
    <mat-grid-tile colspan="1">
      <mat-icon color="warn" class="pointer" (click)="onDelete(category)">delete</mat-icon>
    </mat-grid-tile>
    <ng-container *ngIf="category?.categorySubs && category?.categorySubs.length > 0">
      <ng-container *ngFor="let categorySubItem of category?.categorySubs">
        <mat-grid-tile colspan="1">
        </mat-grid-tile>
        <mat-grid-tile colspan="5">
          <mat-form-field style="min-width: 200px">
            <input required matInput [value]="categorySubItem?.name" maxlength="200">
          </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile colspan="2"></mat-grid-tile>
        <mat-grid-tile colspan="1"></mat-grid-tile>
        <mat-grid-tile colspan="1"></mat-grid-tile>
        <mat-grid-tile colspan="1"></mat-grid-tile>
        <mat-grid-tile colspan="1"></mat-grid-tile>
      </ng-container>
    </ng-container>
  </mat-grid-list>
</div><br>
<div class="mb-30">
  <button mat-raised-button color="primary" (click)="onNewCategory()">Thêm mới</button>&nbsp;&nbsp;
  <button mat-raised-button color="primary" (click)="onSaveCategory()">Cập nhật</button>
</div>
