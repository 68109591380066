import {environment} from '../../../environments/environment';

export class Path {
  static API_BACKEND = environment.API_BACKEND;
  static HOST = environment.HOST;
  static WEB_HOST = environment.WEB_HOST;
  static QA = Path.API_BACKEND + '/qa';
  static COMMENT = Path.API_BACKEND + '/admin/comment';
  static MEDIA = Path.API_BACKEND + '/media/images';
  static POST_HOME_PAGE = Path.API_BACKEND + '/postHomePage';
  static SEARCH = Path.API_BACKEND + '/search';
  static POST = Path.API_BACKEND + '/posts';
  static ROLES = Path.API_BACKEND + '/user/roles';
  static SAVE_POST = Path.API_BACKEND + '/posts/new';
  static SAVE_USER = Path.API_BACKEND + '/user/update';
  static SAVE_CATEGORY = Path.API_BACKEND + '/category/new';
  static SAVE_QUESTION = Path.API_BACKEND + '/qa/admin/ask';
  static SAVE_COMMENT = Path.API_BACKEND + '/admin/comment';
  static SAVE_ANSWER = Path.API_BACKEND + '/qa/answer';
  static DELETE_POST = Path.POST + '/del';
  static DELETE_USER = Path.API_BACKEND + '/user/delete';
  static DELETE_CATEGORY = Path.API_BACKEND + '/category/del';
  static DELETE_QUESTION = Path.QA + '/question/del';
  static DELETE_COMMENT = Path.API_BACKEND  + '/comment/del';
  static DELETE_MEDIA = Path.API_BACKEND  + '/delete/image';
  static ID = ':id';
  static SETTINGS = Path.API_BACKEND + '/settings';
  static SETTING = Path.API_BACKEND + '/setting';
  static USERS = Path.API_BACKEND + '/users';
  static CATEGORY = Path.API_BACKEND + '/category/all';
  static IMAGE_UPLOAD = Path.API_BACKEND + '/upload/img';
  static VIDEO_UPLOAD = Path.API_BACKEND + '/upload/vid';
  static COMMENT_UN_APPROVE = Path.API_BACKEND + '/admin/comment/unappr';
  static QA_NOT_REPLY = Path.API_BACKEND + '/qa/admin/norep';
}
