<mat-card>
  <mat-card-title>Câu hỏi</mat-card-title>
  <mat-card-content>
<!--    <mat-label>Câu hỏi từ <b-->
<!--      style="color: #df6e0a">{{data.userName}}</b>, {{data.createAt * 1000 | date: 'd/M/yyyy HH:mm'}}</mat-label>-->
    <form [formGroup]="questionForm" (ngSubmit)="onSaveQuestion()">
      <mat-form-field class="mb-20">
        <mat-label>Họ tên</mat-label>
        <input type="text" matInput placeholder="" formControlName="anonymousName" maxlength="200" required>
        <mat-error *ngIf="questionForm.controls.anonymousName.hasError('required')">
          Bạn cần nhập vào họ tên
        </mat-error>
      </mat-form-field>
      <mat-form-field class="mb-20">
        <mat-label>Tiêu đề</mat-label>
        <input type="text" matInput placeholder="" formControlName="title"
               maxlength="200" required>
        <mat-error *ngIf="questionForm.controls.title.hasError('required')">
          Bạn cần nhập vào tiêu đề câu hỏi
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Nội dung</mat-label>
        <textarea required matInput placeholder="" matTextareaAutosize matAutosizeMinRows="15" matAutosizeMaxRows="4"
                  formControlName="content" maxlength="20000"></textarea>
        <mat-error *ngIf="questionForm.controls.content.hasError('required')">
          Bạn cần nhập vào nội dung chi tiết câu hỏi
        </mat-error>
      </mat-form-field>

      <p *ngIf="error" class="error">
        {{ error }}
      </p>

      <mat-checkbox class="example-margin" formControlName="isApproved">Trạng thái phê duyệt</mat-checkbox>
      <div class="button">
        <button type="submit" mat-button color="primary">Cập nhật</button>
      </div>
    </form>
  </mat-card-content>
</mat-card>
<br>
<!--form answer-->
<mat-card>
  <mat-card-title>Trả Lời</mat-card-title>
  <mat-card-content>
    <form [formGroup]="answerForm" (ngSubmit)="onSaveAnswer()">
      <quill-editor [styles]="{height: '500px'}"
                    [modules]="modules"
                    [(ngModel)]="contentAnswer"
                    formControlName="contentAnswer"
      ></quill-editor>
      <mat-error style="font-size: 12px" *ngIf="contentAnswer == '' || !contentAnswer">
        Cần nhập vào nội dung trả lời
      </mat-error>

      <div class="button">
        <button type="submit" mat-button color="primary">Cập nhật</button>
      </div>
    </form>
  </mat-card-content>
</mat-card>
