import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute} from '@angular/router';
import {Comment} from '../../../shared/models/response/comment';
import {CommentService} from '../../../shared/services/comment.service';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-comment-save',
  templateUrl: './comment-save-dialog.component.html',
  styleUrls: ['./comment-save-dialog.component.css']
})
export class CommentSaveDialogComponent implements OnInit {
  commentForm = new FormGroup({
    content: new FormControl(''),
    isApproved: new FormControl(false),
  });
  data: Comment;
  @Input() error: string | null;

  @Output() submitEM = new EventEmitter();

  modules: any;

  constructor(private commentService: CommentService,
              private toastr: ToastrService,
              // private activatedRoute: ActivatedRoute,
              @Inject(MAT_DIALOG_DATA) public id: number) {
  }

  ngOnInit(): void {
    // this.id = Number(this.activatedRoute.snapshot.paramMap.get('id'));
    this.getComment(this.id);
  }

  getComment(id: number): void {
    this.commentService.getCommentDetail(id).subscribe(
      (result) => {
        if (result) {
          this.data = result.data;
          this.commentForm.controls.content.setValue(this.data.content);
          this.commentForm.controls.isApproved.setValue(this.data.isApproved);
        }
      },
      (error) => {
        // console.log(error);
      }
    );
  }

  onSaveComment(): void {
    if (this.commentForm.invalid) {
      return;
    }
    // TODO: Use EventEmitter with form value
    const commentFormValue = this.commentForm.value;
    const saveComment = new Comment();
    saveComment.id = this.id;
    saveComment.content = commentFormValue.content;
    saveComment.isApproved = commentFormValue.isApproved;
    this.saveComment(saveComment);
  }

  saveComment(comment: Comment): void {
    this.commentService.saveComment(comment).subscribe((result) => {
      if (result) {
        this.toastr.success('Cập nhật bình luận thành công!', null, {timeOut: 12000});
      }
    }, (e) => {
      console.log(e);
    });
  }

}
