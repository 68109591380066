import {Component, OnInit} from '@angular/core';
import {SettingService} from '../../shared/services/setting.service';
import {Setting} from '../../shared/models/response/setting';
import {SettingType} from '../../shared/models/enum/setting-type';
import {ToastrService} from 'ngx-toastr';
import {AbstractControl, FormControl} from '@angular/forms';
import {MediaService} from '../../shared/services/media.service';
import {Path} from '../../shared/common/path';

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.css']
})
export class SettingComponent implements OnInit {
  headerImgId: number;
  headerColorId: number;
  footerImgId: number;
  footerColorId: number;
  footerText1Id: number;
  footerText2Id: number;
  contactEmailId: number;
  contactFacebookId: number;
  cate3ImgId: number;
  cate3ColorId: number;
  slide1Id: number;
  slide2Id: number;
  slide3Id: number;
  headerImg: string;
  headerImgCtr: AbstractControl = new FormControl(null);
  headerColor: string;
  headerColorCtr: AbstractControl = new FormControl(null);
  footerImg: string;
  footerImgCtr: AbstractControl = new FormControl(null);
  footerColor: string;
  footerColorCtr: AbstractControl = new FormControl(null);
  footerText1: string;
  footerText2: string;
  contactEmail: string;
  contactFacebook: string;
  cate3Img: string;
  cate3ImgCtr: AbstractControl = new FormControl(null);
  cate3Color: string;
  cate3ColorCtr: AbstractControl = new FormControl(null);
  slide1: string;
  slide1Ctr: AbstractControl = new FormControl(null);
  slide2: string;
  slide2Ctr: AbstractControl = new FormControl(null);
  slide3: string;
  slide3Ctr: AbstractControl = new FormControl(null);
  settings: Setting[];
  request: Setting[];
  host = Path.HOST;

  constructor(private settingService: SettingService,
              private toastr: ToastrService,
              private mediaService: MediaService) {
  }

  ngOnInit(): void {
    this.getSettings();
  }

  public get settingType(): typeof SettingType {
    return SettingType;
  }

  getSettings(): void {
    this.settingService.getSettings().subscribe((result) => {
      if (result) {
        this.settings = result;
        for (const setting of this.settings) {
          switch (setting.name) {
            case String(this.settingType.header_img):
              this.headerImgId = setting.id;
              this.headerImg = setting.value ? (this.host + setting.value) : null;
              break;
            case String(this.settingType.header_color):
              this.headerColorId = setting.id;
              this.headerColor = setting.value;
              break;
            case String(this.settingType.footer_img):
              this.footerImgId = setting.id;
              this.footerImg = setting.value ? (this.host + setting.value) : null;
              break;
            case String(this.settingType.footer_color):
              this.footerColorId = setting.id;
              this.footerColor = setting.value;
              break;
            case String(this.settingType.footer_text1):
              this.footerText1Id = setting.id;
              this.footerText1 = setting.value;
              break;
            case String(this.settingType.footer_text2):
              this.footerText2Id = setting.id;
              this.footerText2 = setting.value;
              break;
            case String(this.settingType.contact_email):
              this.contactEmailId = setting.id;
              this.contactEmail = setting.value;
              break;
            case String(this.settingType.contact_facebook):
              this.contactFacebookId = setting.id;
              this.contactFacebook = setting.value;
              break;
            case String(this.settingType.cat_3_img):
              this.cate3ImgId = setting.id;
              this.cate3Img = setting.value ? (this.host + setting.value) : null;
              break;
            case String(this.settingType.cat_3_color):
              this.cate3ColorId = setting.id;
              this.cate3Color = setting.value;
              break;
            case String(this.settingType.slide_1):
              this.slide1Id = setting.id;
              this.slide1 = setting.value ? (this.host + setting.value) : null;
              break;
            case String(this.settingType.slide_2):
              this.slide2Id = setting.id;
              this.slide2 = setting.value ? (this.host + setting.value) : null;
              break;
            case String(this.settingType.slide_3):
              this.slide3Id = setting.id;
              this.slide3 = setting.value ? (this.host + setting.value) : null;
              break;
          }
        }
      }
    }, (e) => {
      console.log(e);
    });
  }

  saveComment(request: Setting[]): void {
    this.settingService.saveSettings(request).subscribe((result) => {
      if (result) {
        this.toastr.success('Cập nhật thành công!', null, {timeOut: 8000});
      }
    }, (e) => {
      console.log(e);
    });
  }

  saveHeaderSetting(): void {
    if (this.headerColorCtr.status == 'INVALID') {
      this.toastr.warning('Màu không hợp lệ', null, {timeOut: 5000});
      return;
    }
    this.request = [
      {
        id: this.headerImgId,
        name: this.settingType.header_img,
        value: this.headerImg.replace(this.host, '')
      },
      {
        id: this.headerColorId,
        name: this.settingType.header_color,
        value: this.headerColorCtr.value.hex ? '#' + this.headerColorCtr.value.hex : this.headerColorCtr.value
      },
    ];

    this.saveComment(this.request);
  }

  saveFooterSetting(): void {
    if (this.headerColorCtr.status == 'INVALID') {
      this.toastr.warning('Màu không hợp lệ', null, {timeOut: 5000});
      return;
    }
    this.request = [
      {
        id: this.footerImgId,
        name: this.settingType.footer_img,
        value: this.footerImg.replace(this.host, '')
      },
      {
        id: this.footerColorId,
        name: this.settingType.footer_color,
        value: this.footerColorCtr.value.hex ? '#' + this.footerColorCtr.value.hex : this.footerColorCtr.value
      },
      {
        id: this.footerText1Id,
        name: this.settingType.footer_text1,
        value: this.footerText1
      },
      {
        id: this.footerText2Id,
        name: this.settingType.footer_text2,
        value: this.footerText2
      },
    ];
    this.saveComment(this.request);
  }

  saveSocialLinkSetting(): void {
    this.request = [
      {
        id: this.contactEmailId,
        name: this.settingType.contact_email,
        value: this.contactEmail
      },
      {
        id: this.contactFacebookId,
        name: this.settingType.contact_facebook,
        value: this.contactFacebook
      },
    ];
    this.saveComment(this.request);
  }

  saveTemplate3Setting(): void {
    if (this.cate3ColorCtr.status == 'INVALID') {
      this.toastr.warning('Màu không hợp lệ', null, {timeOut: 5000});
      return;
    }
    this.request = [
      {
        id: this.cate3ImgId,
        name: this.settingType.cat_3_img,
        value: this.cate3Img.replace(this.host, '')
      },
      {
        id: this.cate3ColorId,
        name: this.settingType.cat_3_color,
        value: this.cate3ColorCtr.value.hex ? '#' + this.cate3ColorCtr.value.hex : this.cate3ColorCtr.value
      },
    ];
    this.saveComment(this.request);
  }

  saveSlideSetting(): void {
    this.request = [
      {
        id: this.slide1Id,
        name: this.settingType.slide_1,
        value: this.slide1.replace(this.host, '')
      },
      {
        id: this.slide2Id,
        name: this.settingType.slide_2,
        value: this.slide2.replace(this.host, '')
      },
      {
        id: this.slide3Id,
        name: this.settingType.slide_3,
        value: this.slide3.replace(this.host, '')
      },
    ];
    this.saveComment(this.request);
  }

  uploadHeaderImg(files: FileList): void {
    const fileUpload = files.item(0);
    this.mediaService.uploadImage(fileUpload).subscribe(res => {
        this.headerImg = res.data.path;
      }, (err) => this.toastr.warning('Ảnh Banner gặp sự cố upload', null, {timeOut: 12000})
    );
  }

  uploadFooterImg(files: FileList): void {
    const fileUpload = files.item(0);
    this.mediaService.uploadImage(fileUpload).subscribe(res => {
        this.footerImg = res.data.path;
      }, (err) => this.toastr.warning('Ảnh Footer gặp sự cố upload', null, {timeOut: 12000})
    );
  }

  uploadCate3Img(files: FileList): void {
    const fileUpload = files.item(0);
    this.mediaService.uploadImage(fileUpload).subscribe(res => {
        this.cate3Img = res.data.path;
      }, (err) => this.toastr.warning('Ảnh template 3 gặp sự cố upload', null, {timeOut: 12000})
    );
  }

  uploadSlide1Img(files: FileList): void {
    const fileUpload = files.item(0);
    this.mediaService.uploadImage(fileUpload).subscribe(res => {
        this.slide1 = res.data.path;
      }, (err) => this.toastr.warning('Ảnh slide 1 gặp sự cố upload', null, {timeOut: 12000})
    );
  }

  uploadSlide2Img(files: FileList): void {
    const fileUpload = files.item(0);
    this.mediaService.uploadImage(fileUpload).subscribe(res => {
        this.slide2 = res.data.path;
      }, (err) => this.toastr.warning('Ảnh slide 2 gặp sự cố upload', null, {timeOut: 12000})
    );
  }

  uploadSlide3Img(files: FileList): void {
    const fileUpload = files.item(0);
    this.mediaService.uploadImage(fileUpload).subscribe(res => {
        this.slide3 = res.data.path;
      }, (err) => this.toastr.warning('Ảnh slide 3 gặp sự cố upload', null, {timeOut: 12000})
    );
  }
}
