import {Role} from './role';

export class User {
  id: number;
  userName: string;
  email: string;
  imageUrl: any;
  emailVerified: boolean;
  provider: string;
  providerId: any;
  role: Role;
  isEnabled: boolean;
  createAt: number;
  updateAt: number;
  accessToken?: string;
  tokenType?: string;
  password?: string;
  newPwd?: string;
}
