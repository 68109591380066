<h2>Quản lý bình luận</h2>
<div class="container">
  <mat-form-field class="mb-20 full-width">
    <mat-label>Tìm kiếm</mat-label>
    <input type="text" matInput placeholder="" [(ngModel)]="keyword" (ngModelChange)="onSearch()"
           maxlength="200">
  </mat-form-field>
  <br>
  <mat-form-field class="ml-10">
    <mat-label>Trạng thái phê duyệt</mat-label>
    <mat-select (selectionChange)="onClickFilterApproved($event)" style="font-size: 14px; font-weight: 500">
      <mat-option value="all">Tất cả</mat-option>
      <mat-option value="true">Đã phê duyệt</mat-option>
      <mat-option value="false">Chưa phê duyệt</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-label style="float: right;padding: 22px 1px 0 0" >
    <span style="cursor:pointer;color: #4c2525; text-decoration: none" (click)="reload()">
      <mat-icon>refresh</mat-icon>
    </span>
  </mat-label>
  <div class="mat-elevation-z8 mt-10">
    <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)">

      <!-- ID Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header width="4%"> ID</th>
        <td mat-cell *matCellDef="let row">{{row?.id}}</td>
      </ng-container>


      <!-- title Column -->
      <ng-container matColumnDef="content">
        <th mat-header-cell *matHeaderCellDef mat-sort-header width="30%"> Comment</th>
        <td mat-cell *matCellDef="let row"><a style="text-decoration: none;  cursor: pointer; font-weight: 500"
                                              class="pointer"
                                              (click)="onOpenCommentDetail(row)">{{row?.content | slice:0:200}}<span
          *ngIf="row.content?.length > 200">...</span></a></td>
      </ng-container>

      <!-- Comment parent Column -->
      <ng-container matColumnDef="parentComment">
        <th mat-header-cell *matHeaderCellDef width="20%"> Comment cha</th>
        <td mat-cell *matCellDef="let row">{{row?.parentComment | slice:0:200}}</td>
      </ng-container>

      <!-- Comment parent IdColumn -->
      <ng-container matColumnDef="parentId">
        <th mat-header-cell *matHeaderCellDef width="4%"> Id cha</th>
        <td mat-cell *matCellDef="let row"><ng-container *ngIf="row?.parentId > 0">{{row?.parentId}}</ng-container></td>
      </ng-container>

      <!-- Post title Column -->
      <ng-container matColumnDef="post_title">
        <th mat-header-cell *matHeaderCellDef width="20%"> Bài viết</th>
        <td mat-cell *matCellDef="let row">{{row?.postTitle}}</td>
      </ng-container>

      <!-- user name Column -->
      <ng-container matColumnDef="user_name">
        <th mat-header-cell *matHeaderCellDef width="15%"> Người comment</th>
        <td mat-cell *matCellDef="let row">{{row?.userName}}</td>
      </ng-container>

      <!-- isApproved Column -->
      <ng-container matColumnDef="is_approved">
        <th mat-header-cell *matHeaderCellDef mat-sort-header width="10%"> Phê duyệt</th>
        <td mat-cell *matCellDef="let row">
          <mat-icon *ngIf="row.isApproved">done</mat-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="create_at">
        <th mat-header-cell *matHeaderCellDef mat-sort-header width="8%"> Ngày tạo</th>
        <td mat-cell *matCellDef="let row"> {{row?.createAt * 1000 | date: 'd/M/yyyy HH:mm'}} </td>
      </ng-container>

      <ng-container matColumnDef="update_at">
        <th mat-header-cell *matHeaderCellDef mat-sort-header width="8%"> &nbsp;&nbsp;Ngày cập nhật</th>
        <td mat-cell *matCellDef="let row">
          &nbsp;&nbsp;{{row.updateAt * 1000 | date: 'd/M/yyyy &nbsp;&nbsp;HH:mm'}} </td>
      </ng-container>

      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef width="7%">Cập nhật</th>
        <td mat-cell *matCellDef="let row">
          <mat-icon color="primary" class="pointer" (click)="onEdit(row?.id)">edit</mat-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef width="7%">Xóa</th>
        <td mat-cell *matCellDef="let row">
          <mat-icon color="warn" class="pointer" (click)="onDelete(row.id)">delete</mat-icon>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">Không có dữ liệu</td>
      </tr>
    </table>

    <mat-paginator [length]="length"
                   [pageSize]="pageSize"
                   [pageSizeOptions]="pageSizeOptions"
                   (page)="onPageChange($event)">
    </mat-paginator>
  </div>
  <br>
  <br>
</div>
