import {Injectable, Output} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class LoadingService {
  @Output()
  loadingChange: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  show(): void {
    this.loadingChange.next(true);
  }

  hide(): void {
    this.loadingChange.next(false);
  }
}
