import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {User} from '../../../shared/models/response/user';
import {UserService} from '../../../shared/services/user.service';
import {Role} from '../../../shared/models/response/role';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';

@Component({
  selector: 'app-user-save-dialog',
  templateUrl: './user-save-dialog.component.html',
  styleUrls: ['./user-save-dialog.component.css']
})
export class UserSaveDialogComponent implements OnInit {
  isChangePassword = false;
  email: string;
  isEnabled: boolean;
  userName: string;
  role = new Role();
  data: User;
  roles: Role[];
  @Input() error: string | null;
  userForm: FormGroup;

  @Output() submitEM = new EventEmitter();

  modules: any;
  userTypes = ['SUPER_ADMIN', 'ADMIN', 'CREATOR', 'USER'];

  constructor(private userService: UserService,
              private toastr: ToastrService,
              // private activatedRoute: ActivatedRoute,
              @Inject(MAT_DIALOG_DATA) public user: User,
              private formBuilder: FormBuilder,
              private router: Router) {
  }

  ngOnInit(): void {
    this.getRoles();
    this.initForm();
  }

  initForm(): void {
    this.userForm = this.formBuilder.group({
      userName: [this.user.userName],
      email: {value: this.user.email, disabled: true},
      password: ['',
        [Validators.maxLength(200),
          Validators.minLength(6)],
      ],
      rePassword: ['',
        [Validators.maxLength(200),
          Validators.minLength(6)],
      ],
      isEnabled: [this.user.isEnabled],
      role: [this.user.role ? this.user.role.id : null, [Validators.required]]
    });
  }

  // public get userTypes(): typeof UserType {
  //   return UserType;
  // }

  onOpenChangePassword(): void {
    this.isChangePassword = !this.isChangePassword;
  }

  changingRoleValue(event): void {
    this.role.name = event.value;
  }

  onSaveComment(): void {
    if (this.userForm.invalid) {
      return;
    }
    const userFormValue = this.userForm.value;
    const user = new User();
    const role = new Role();
    role.id = userFormValue.role;
    user.id = this.user.id;
    user.userName = userFormValue.userName;
    user.newPwd = userFormValue.password;
    user.email = userFormValue.email;
    user.role = role;
    user.isEnabled = userFormValue.isEnabled;
    if (role.id > 0) {
    } else {
      this.toastr.error('Role là bắt buộc');
      return;
    }

    this.saveUser(user);
  }

  saveUser(user: User): void {
    this.userService.saveUser(user).subscribe((result) => {
      if (result) {
        this.toastr.success('Cập nhật User thành công!', null, {timeOut: 12000});
        this.reload();
      }
    }, (e) => {
      console.log(e);
    });
  }

  getRoles() {
    this.userService.getRoles().subscribe((result) => {
      if (result) {
        this.roles = result.data;
      }
    }, (e) => {
      console.log(e);
    });
  }

  reload(): void {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigateByUrl('/user');
    });
  }
}
