import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {MediaMatcher} from '@angular/cdk/layout';
import {AuthService} from '../../shared/services/auth.service';
import {LoadingService} from '../../shared/services/loading.service';
import {User} from '../../shared/models/response/user';
import {UserType} from '../../shared/models/enum/user-type';
import {QaService} from '../../shared/services/qa.service';
import {CommentService} from '../../shared/services/comment.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnInit, OnDestroy {
  loading = true;
  isLogin = false;
  mobileQuery: MediaQueryList;
  currentUser: User;
  countQaNotReply: number;
  countCommentUnApprove: number;

  fillerNav = Array.from({length: 5}, (_, i) => `Nav Item ${i + 1}`);

  fillerContent = Array.from({length: 5}, () =>
    `Lorem ipsum dolor sit ametss.123`);

  private mobileQueryListener: () => void;

  constructor(changeDetectorRef: ChangeDetectorRef, media: MediaMatcher,
              private authService: AuthService,
              private qaService: QaService,
              private commentService: CommentService,
              private loadingService: LoadingService) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this.mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this.mobileQueryListener);
  }

  ngOnInit(): void {
    this.currentUser = this.authService.currentUserValue;
    if (localStorage.getItem('currentUser') === null || localStorage.getItem('currentUser') === 'undefined') {
      this.isLogin = false;
    } else {
      this.getCommentUnApprove();
      this.getQaNotReply();
      this.isLogin = true;
    }

    this.loadingService.loadingChange.subscribe((result =>
        setTimeout(() => this.loading = result, 0)
    ));

  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this.mobileQueryListener);
  }

  logout(): void {
    this.authService.logout();
    location.reload(true);
  }

  get isAdmin() {
    return this.currentUser && this.currentUser.role ? this.currentUser.role.name == UserType.ADMIN : false;
  }

  getCommentUnApprove(): void {
    this.commentService.getCommentUnApprove().subscribe((result) => {
      if (result) {
        this.countCommentUnApprove = result.data;
      }
    }, (e) => {
      console.log(e);
    });
  }

  getQaNotReply(): void {
    this.qaService.getQaNotReply().subscribe((result) => {
      if (result) {
        this.countQaNotReply = result.data;
      }
    }, (e) => {
      console.log(e);
    });
  }
}
