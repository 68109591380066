import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {QaListComponent} from '../qa-list.component';
import {Qa} from '../../../shared/models/response/qa';

@Component({
  selector: 'app-qa-detail',
  templateUrl: './qa-detail.component.html',
  styleUrls: ['./qa-detail.component.css']
})
export class QaDetailComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: Qa) { }

  ngOnInit(): void {
  }

}
