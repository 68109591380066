export enum SettingType {
  menu_display = 'menu_display',
  post_limit_hp = 'post_limit_hp',
  image_path = 'image_path',
  footer_color = 'footer_color',
  footer_img = 'footer_img',
  video_path = 'video_path',
  media_path = 'media_path',
  header_img = 'header_img',
  header_color = 'header_color',
  cat_1_img = 'cat_1_img',
  cat_2_img = 'cat_2_img',
  cat_3_img = 'cat_3_img',
  cat_4_img = 'cat_4_img',
  cat_5_img = 'cat_5_img',
  cat_6_img = 'cat_6_img',
  cat_1_color = 'cat_1_color',
  cat_2_color = 'cat_2_color',
  cat_3_color = 'cat_3_color',
  footer_text1 = 'footer_text1',
  footer_text2 = 'footer_text2',
  footer_text3 = 'footer_text3',
  contact_email = 'contact_email',
  contact_facebook = 'contact_facebook',
  slide_1 = 'slide_1',
  slide_2 = 'slide_2',
  slide_3 = 'slide_3',

}
