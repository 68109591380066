<h2>Cài đặt</h2>
<mat-card class="mb-20">
  <h4>Header</h4>
  <div *ngFor="let setting of settings">
    <ng-container [ngSwitch]="setting.name">
      <ng-container *ngSwitchCase="settingType?.header_color">
        <mat-card-content style="display: inline-flex">
          <mat-form-field>
            <input matInput placeholder="Header color" [ngxMatColorPicker]="picker" [formControl]="headerColorCtr" [(ngModel)]="headerColor"
                   [style.color]="headerColor ? headerColor : ''">
            <ngx-mat-color-toggle matSuffix [for]="picker"></ngx-mat-color-toggle>
            <ngx-mat-color-picker #picker></ngx-mat-color-picker>
          </mat-form-field>
          <div class="circle ml-20" style="background-color: {{headerColor}}"></div>
        </mat-card-content>
      </ng-container>
      <ng-container *ngSwitchCase="settingType?.header_img">
        <mat-card-content>
          <mat-form-field>
            <input matInput [formControl]="headerImgCtr" [(ngModel)]="headerImg" placeholder="Header image">
          </mat-form-field>
          <div class="ml-20 mb-10">Upload: <input type="file" [(ngModel)]="headerImg" (change)="uploadHeaderImg($event.target.files)"></div>
          <img class="mr-20 ml-20" style="width: auto; height: 60px;border: #dad8d8 1px dashed;" src="{{headerImg}}">
        </mat-card-content>
      </ng-container>
    </ng-container>
  </div>
  <button mat-stroked-button color="primary" (click)="saveHeaderSetting()">Cập nhật</button>
</mat-card>

<mat-card class="mb-20">
  <h4>Footer</h4>
  <div *ngFor="let setting of settings">
    <ng-container [ngSwitch]="setting.name">
      <ng-container *ngSwitchCase="settingType?.footer_color">
        <mat-card-content style="display: inline-flex">
          <mat-form-field>
            <input matInput placeholder="Footer color" [ngxMatColorPicker]="picker" [formControl]="footerColorCtr" [(ngModel)]="footerColor"
                   [style.color]="footerColor ? footerColor : ''">
            <ngx-mat-color-toggle matSuffix [for]="picker"></ngx-mat-color-toggle>
            <ngx-mat-color-picker #picker></ngx-mat-color-picker>
          </mat-form-field>
          <div class="circle ml-20" style="background-color: {{footerColor}}"></div>
        </mat-card-content>
      </ng-container>
      <ng-container *ngSwitchCase="settingType?.footer_img">
        <mat-card-content>
          <mat-form-field>
            <input matInput [formControl]="footerImgCtr" [(ngModel)]="footerImg" placeholder="Footer image">
          </mat-form-field>
          <div class="ml-20 mb-10">Upload: <input type="file" [(ngModel)]="footerImg" (change)="uploadFooterImg($event.target.files)"></div>
          <img class="mr-20 ml-20" style="width: auto; height: 60px;border: #dad8d8 1px dashed;" src="{{footerImg}}">
        </mat-card-content>
      </ng-container>
      <ng-container *ngSwitchCase="settingType?.footer_text1">
        <mat-card-content>
          <mat-form-field class="full-width-input">
            <input matInput type="text" placeholder="Footer text 1" [(ngModel)]="footerText1">
          </mat-form-field>
        </mat-card-content>
      </ng-container>
      <ng-container *ngSwitchCase="settingType?.footer_text2">
        <mat-card-content>
          <mat-form-field class="full-width-input">
            <input matInput type="text" placeholder="Footer text 2" [(ngModel)]="footerText2">
          </mat-form-field>
        </mat-card-content>
      </ng-container>
    </ng-container>
  </div>
  <button mat-stroked-button color="primary" (click)="saveFooterSetting()">Cập nhật</button>
</mat-card>

<mat-card class="mb-20">
  <h4>Social link</h4>
  <div *ngFor="let setting of settings">
    <ng-container [ngSwitch]="setting.name">
      <ng-container *ngSwitchCase="settingType?.contact_email">
        <mat-card-content>
          <mat-form-field class="full-width-input">
            <input matInput type="text" placeholder="Contact email" [(ngModel)]="contactEmail">
          </mat-form-field>
        </mat-card-content>
      </ng-container>
      <ng-container *ngSwitchCase="settingType?.contact_facebook">
        <mat-card-content>
          <mat-form-field class="full-width-input">
            <input matInput type="text" placeholder="Contact facebook" [(ngModel)]="contactFacebook">
          </mat-form-field>
        </mat-card-content>
      </ng-container>
    </ng-container>
  </div>
  <button mat-stroked-button color="primary" (click)="saveSocialLinkSetting()">Cập nhật</button>
</mat-card>

<mat-card class="mb-20">
  <h4>Slide</h4>
  <div *ngFor="let setting of settings">
    <ng-container [ngSwitch]="setting.name">
      <ng-container *ngSwitchCase="settingType?.slide_1">
        <mat-card-content>
          <mat-form-field>
            <input matInput [formControl]="slide1Ctr" [(ngModel)]="slide1" placeholder="Slide 1 image">
          </mat-form-field>
          <div class="ml-20 mb-10">Upload: <input type="file" [(ngModel)]="slide1" (change)="uploadSlide1Img($event.target.files)"></div>
          <img class="mr-20 ml-20" style="width: auto; height: 60px;border: #dad8d8 1px dashed;" src="{{slide1}}">
        </mat-card-content>
      </ng-container>
      <ng-container *ngSwitchCase="settingType?.slide_2">
        <mat-card-content>
          <mat-form-field>
            <input matInput [formControl]="slide2Ctr" [(ngModel)]="slide2" placeholder="Slide 2 image">
          </mat-form-field>
          <div class="ml-20 mb-10">Upload: <input type="file" [(ngModel)]="slide2" (change)="uploadSlide2Img($event.target.files)"></div>
          <img class="mr-20 ml-20" style="width: auto; height: 60px;border: #dad8d8 1px dashed;" src="{{slide2}}">
        </mat-card-content>
      </ng-container>
      <ng-container *ngSwitchCase="settingType?.slide_3">
        <mat-card-content>
          <mat-form-field>
            <input matInput [formControl]="slide3Ctr" [(ngModel)]="slide3" placeholder="Slide 3 image">
          </mat-form-field>
          <div class="ml-20 mb-10">Upload: <input type="file" [(ngModel)]="slide3" (change)="uploadSlide3Img($event.target.files)"></div>
          <img class="mr-20 ml-20" style="width: auto; height: 60px;border: #dad8d8 1px dashed;" src="{{slide3}}">
        </mat-card-content>
      </ng-container>
    </ng-container>
  </div>
  <button mat-stroked-button color="primary" (click)="saveSlideSetting()">Cập nhật</button>
</mat-card>

<mat-card class="mb-20">
  <h4>Template 3</h4>
  <div *ngFor="let setting of settings">
    <ng-container [ngSwitch]="setting.name">
      <ng-container *ngSwitchCase="settingType?.cat_3_color">
        <mat-card-content style="display: inline-flex">
          <mat-form-field>
            <input matInput placeholder="Template 3 color" [ngxMatColorPicker]="picker" [formControl]="cate3ColorCtr" [(ngModel)]="cate3Color"
                   [style.color]="cate3Color ? cate3Color : ''">
            <ngx-mat-color-toggle matSuffix [for]="picker"></ngx-mat-color-toggle>
            <ngx-mat-color-picker #picker></ngx-mat-color-picker>
          </mat-form-field>
          <div class="circle ml-20" style="background-color: {{footerColor}}"></div>
        </mat-card-content>
      </ng-container>
      <ng-container *ngSwitchCase="settingType?.cat_3_img">
        <mat-card-content>
          <mat-form-field>
            <input matInput [formControl]="cate3ImgCtr" [(ngModel)]="cate3Img" placeholder="Template 3 image">
          </mat-form-field>
          <div class="ml-20 mb-10">Upload: <input type="file" [(ngModel)]="cate3Img" (change)="uploadCate3Img($event.target.files)"></div>
          <img class="mr-20 ml-20" style="width: auto; height: 60px;border: #dad8d8 1px dashed;" src="{{cate3Img}}">
        </mat-card-content>
      </ng-container>
    </ng-container>
  </div>
  <button mat-stroked-button color="primary" (click)="saveTemplate3Setting()">Cập nhật</button>
</mat-card>
