export class PostRequest {
  id: number;
  categoryId: number;
  categorySubId: number;
  content: string;
  description: string;
  feature: boolean;
  isFeature: boolean;
  isPublish: boolean;
  postType: string;
  publish: boolean;
  thumbnail: string;
  title: string;
  tagPosts: string[];
}
