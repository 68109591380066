import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Path} from '../common/path';
import {Result} from '../models/response/result';
import {Qa} from '../models/response/qa';
import {Answer} from '../models/response/answer';
import {QaRequest} from '../models/request/qa-request';
import {Category} from '../models/response/category';

@Injectable({
  providedIn: 'root'
})
export class QaService {

  constructor(private http: HttpClient) {
  }

  getQa(request: QaRequest): Observable<Result<Qa[]>> {
    return this.http.get<any>(Path.QA + '/admin' + '?limit=' + request.limit +
      '&page=' + request.page + (request.keyword ? ('&keyword=' + request.keyword) : '') +
      (request.orderby ? ('&orderby=' + request.orderby) : '') +
      (request.replied_filter != null ? ('&replied_filter=' + request.replied_filter) : '') +
      (request.approved_filter != null ? ('&approved_filter=' + request.approved_filter) : '')
      , {}).map(
      result => {
        return result;
      }, error => {
        return error;
      }
    );
  }

  getQaDetail(id: number): Observable<Result<Qa>> {
    return this.http.get<any>(Path.QA + '/' + id, {}).map(
      result => {
        return result;
      }, error => {
        return error;
      }
    );
  }

  saveQuestion(request: Qa): Observable<Result<any>> {
    return this.http.post(Path.SAVE_QUESTION, request).map((result: Result<any>) => {
      return result;
    }, error => {
      return error;
    });
  }

  saveAnswer(request: Answer): Observable<Result<any>> {
    return this.http.post(Path.SAVE_ANSWER, request).map((result: Result<any>) => {
      return result;
    }, error => {
      return error;
    });
  }

  delete(id: number): Observable<Result<any>> {
    return this.http.post(Path.DELETE_QUESTION + '/' + id, id).map((result: Result<any>) => {
      return result;
    }, error => {
      return error;
    });
  }

  getQaNotReply(): Observable<Result<any>> {
    return this.http.get<any>(Path.QA_NOT_REPLY).map(
      result => {
        return result;
      }, error => {
        return error;
      }
    );
  }
}
