import {Answer} from './answer';

export class Qa {
  id: number;
  title: string;
  content: string;
  createAt: number;
  updateAt: number;
  userId: number;
  userName: string;
  answers: Answer[];
  isAdminReply: boolean;
  isApproved: boolean;
  friendlyUrl: string;
  anonymousName: string;
}
