import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CategoryService} from '../../../shared/services/category.service';
import {Router} from '@angular/router';

export interface DialogDeleteCategory {
  id: number;
  name: string;
}

@Component({
  selector: 'app-category-delete-dialog',
  templateUrl: './category-delete-dialog.component.html',
  styleUrls: ['./category-delete-dialog.component.css']
})
export class CategoryDeleteDialogComponent implements OnInit {

  constructor(
    private categoryService: CategoryService,
    public dialogRef: MatDialogRef<CategoryDeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDeleteCategory,
    private router: Router) {
  }

  ngOnInit(): void {
  }

  onDelete(): void {
    this.categoryService.delete(this.data.id).subscribe(
      (result) => {
        this.reload();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  reload(): void {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigateByUrl('/category');
    });
  }

}
