import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {CommentRequest} from '../../../shared/models/request/CommentRequest';
import {MatTableDataSource} from '@angular/material/table';
import {MediaService} from '../../../shared/services/media.service';
import {PagingRequest} from '../../../shared/models/request/paging-request';
import {Media} from '../../../shared/models/response/media';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {Path} from '../../../shared/common/path';
import {CommentDeleteDialogComponent} from '../../comment/comment-delete-dialog/comment-delete-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {MediaDeleteDialogComponent} from '../media-delete-dialog/media-delete-dialog.component';
import {ToastrService} from 'ngx-toastr';
import {AbstractControl, FormControl} from '@angular/forms';

@Component({
  selector: 'app-media-list',
  templateUrl: './media-list.component.html',
  styleUrls: ['./media-list.component.css']
})
export class MediaListComponent implements OnInit {
  request: CommentRequest;
  limit: number;
  page: number;
  displayedColumns: string[] = ['id', 'thumbnail' ,'originalName', 'parentFolder', 'imagePath', 'copy', 'delete'];
  dataSource: MatTableDataSource<Media>;
  pageSize: number;
  pageIndex: number;
  orderBy: string;
  length: number;
  pageSizeOptions: number[] = [10, 25, 100];
  keyword: string;
  host = Path.HOST;
  fileImage: AbstractControl = new FormControl(null);
  imageUrl: string;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private mediaService: MediaService,
              private changeDetectorRefs: ChangeDetectorRef,
              private dialog: MatDialog,
              private toastr: ToastrService) {
  }

  ngOnInit(): void {
    this.request = {
      limit: 10,
      page: 0
    };
    this.getMedia(this.request);
  }

  getMedia(request: PagingRequest): void {
    this.mediaService.getImages(request).subscribe(
      (result) => {
        this.length = result.metadata ? result.metadata.total : 100;
        this.dataSource = new MatTableDataSource(result.data);
        this.changeDetectorRefs.detectChanges();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  sortData(sortEvent): void {
    // if (sortEvent.direction === '') {
    //   this.orderBy = 'create_at desc';
    // } else {
    //   this.orderBy = sortEvent.active + ' ' + sortEvent.direction;
    // }
    this.request = {
      keyword: this.keyword,
      limit: this.paginator.pageSize,
      page: this.paginator.pageIndex,
      // orderby: this.orderBy
    };
    this.getMedia(this.request);
  }

  onSearch(): void {
    this.request = {
      keyword: this.keyword,
      limit: 10,
      page: 0,
      // orderby: this.orderBy ? this.orderBy : 'create_at desc'
    };
    this.getMedia(this.request);
  }

  onPageChange(pageEvent): void {
    this.pageSize = pageEvent.pageSize;
    this.pageIndex = pageEvent.pageSize;
    this.request = {
      keyword: this.keyword,
      limit: pageEvent.pageSize,
      page: pageEvent.pageIndex,
      // orderby: this.orderBy ? this.orderBy : 'create_at desc'
    };
    this.getMedia(this.request);
  }

  onDelete(id: number): void {
    this.dialog.open(MediaDeleteDialogComponent, {
      width: '300px',
      data: id
    });
  }

  copyUrl (url: string) {
    var result = this.copyTextToClipboard(url);
    if (result) {
      this.toastr.info('Copied to Clipboard');
    }
  }

  uploadImage(files: FileList): void {
    const fileUpload = files.item(0);
    this.mediaService.uploadImage(fileUpload).subscribe(res => {
        this.imageUrl = this.host + res.data.path;
        this.toastr.success("Upload image thành công.");
      }, (err) => this.toastr.warning('Ảnh gặp sự cố upload', null, {timeOut: 12000})
    );
  }

  copyTextToClipboard(text) {
    var txtArea = document.createElement("textarea");
    txtArea.id = 'txt';
    txtArea.style.position = 'fixed';
    txtArea.style.top = '0';
    txtArea.style.left = '0';
    txtArea.style.opacity = '0';
    txtArea.value = text;
    document.body.appendChild(txtArea);
    txtArea.select();

    try {
      var successful = document.execCommand('copy');
      var msg = successful ? 'successful' : 'unsuccessful';
      // console.log('Copying text command was ' + msg);
      if (successful) {
        return true;
      }
    } catch (err) {
      // console.log('Oops, unable to copy');
    } finally {
      document.body.removeChild(txtArea);
    }
    return false;
  }
}
