import {Observable} from 'rxjs';
import {Result} from '../models/response/result';
import {Post} from '../models/response/post';
import {Path} from '../common/path';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {CategoryRequest} from '../models/request/category-request';
import {Category} from '../models/response/category';


@Injectable()
export class CategoryService {
  constructor(private http: HttpClient) {
  }

  // Get all post category HomePage
  getPosts(request: CategoryRequest): Observable<Result<Post[]>> {
    return this.http.get<any>(Path.POST + '/loadMoreAdmin' + '?category=' + request.id + '&limit=' + request.limit +
      '&page=' + request.page + '&orderby=' + request.orderby + (request.keyword ? ('&keyword=' + request.keyword) : ''), {}).map(
      result => {
        return result;
      }, error => {
        return error;
      }
    );
  }

  getCategoryAll(): Observable<Category[]> {
    return this.http.get<any>(Path.CATEGORY).map(
      result => {
        return result;
      }, error => {
        return error;
      }
    );
  }

  delete(id: number): Observable<Result<any>> {
    return this.http.post(Path.DELETE_CATEGORY + '/' + id, id).map((result: Result<any>) => {
      return result;
    }, error => {
      return error;
    });
  }

  saveCategory(requests: Category[]): Observable<Result<any>> {
    return this.http.post(Path.SAVE_CATEGORY, requests).map((result: Result<any>) => {
      return result;
    }, error => {
      return error;
    });
  }

}
