import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {QaService} from '../../shared/services/qa.service';
import {Qa} from '../../shared/models/response/qa';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {QaDetailComponent} from './qa-detail/qa-detail.component';
import {ToastrService} from 'ngx-toastr';
import {SearchService} from '../../shared/services/search.service';
import {QaRequest} from '../../shared/models/request/qa-request';
import {QaDeleteDialogComponent} from './qa-delete-dialog/qa-delete-dialog.component';
import {QaSaveComponent} from './qa-save/qa-save.component';

@Component({
  selector: 'app-hoi-dap',
  templateUrl: './qa-list.component.html',
  styleUrls: ['./qa-list.component.css']
})
export class QaListComponent implements OnInit {

  repliedFilter: boolean;
  isApproved: boolean;
  request: QaRequest;
  limit: number;
  page: number;
  displayedColumns: string[] = ['icon', 'id', 'title', 'is_approved', 'create_at', 'update_at', 'edit', 'delete'];
  dataSource: MatTableDataSource<Qa>;
  pageSize: number;
  pageIndex: number;
  orderBy: string;
  length: number;
  pageSizeOptions: number[] = [10, 25, 100];
  keyword: string;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private qaService: QaService,
              private router: Router,
              public dialog: MatDialog,
              private toastr: ToastrService,
              private searchService: SearchService,
              private changeDetectorRefs: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.request = {
      limit: 10,
      page: 0
    };
    this.getQa(this.request);
  }

  getQa(request: QaRequest): void {
    this.qaService.getQa(request).subscribe(
      (result) => {
        // this.qas = result.data;
        this.length = result.metadata.total;
        this.dataSource = new MatTableDataSource(result.data);
        this.changeDetectorRefs.detectChanges();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  onPageChange(pageEvent): void {
    this.pageSize = pageEvent.pageSize;
    this.pageIndex = pageEvent.pageSize;
    this.request = {
      keyword: this.keyword,
      replied_filter: (this.repliedFilter === false || this.repliedFilter === true) ? this.repliedFilter : null,
      approved_filter: (this.isApproved === false || this.isApproved === true) ? this.isApproved : null,
      limit: pageEvent.pageSize,
      page: pageEvent.pageIndex,
      orderby: this.orderBy ? this.orderBy : 'create_at desc'
    };
    this.getQa(this.request);
  }

  onClickFilterAll(): void {
    this.repliedFilter = null;
    this.request = {
      keyword: this.keyword,
      limit: 10,
      page: 0,
      orderby: this.orderBy ? this.orderBy : 'create_at desc'
    };
    this.getQa(this.request);
  }

  onClickFilterTrue(): void {
    this.repliedFilter = true;
    this.request = {
      keyword: this.keyword,
      replied_filter: this.repliedFilter,
      approved_filter: this.isApproved,
      limit: 10,
      page: 0,
      orderby: this.orderBy ? this.orderBy : 'create_at desc'
    };
    this.getQa(this.request);
  }

  onClickFilterFalse(): void {
    this.repliedFilter = false;
    this.request = {
      keyword: this.keyword,
      replied_filter: this.repliedFilter,
      approved_filter: this.isApproved,
      limit: 10,
      page: 0,
      orderby: this.orderBy ? this.orderBy : 'create_at desc'
    };
    this.getQa(this.request);
  }

  onClickFilterApproved(isApproved): void {
    if (isApproved.value === 'all') {
      this.isApproved = null;
    } else {
      this.isApproved = (isApproved.value === 'true');
    }
    this.request = {
      keyword: this.keyword,
      replied_filter: this.repliedFilter,
      approved_filter: this.isApproved,
      limit: 10,
      page: 0,
      orderby: this.orderBy ? this.orderBy : 'create_at desc'
    };
    this.getQa(this.request);
  }

  sortData(sortEvent): void {
    if (sortEvent.direction === '') {
      this.orderBy = 'create_at desc';
    } else {
      this.orderBy = sortEvent.active + ' ' + sortEvent.direction;
    }
    this.request = {
      keyword: this.keyword,
      replied_filter: this.repliedFilter,
      approved_filter: this.isApproved,
      limit: this.paginator.pageSize,
      page: this.paginator.pageIndex,
      orderby: this.orderBy
    };
    this.getQa(this.request);
  }

  onSearch(): void {
    this.request = {
      keyword: this.keyword,
      replied_filter: this.repliedFilter,
      approved_filter: this.isApproved,
      limit: 10,
      page: 0,
      orderby: this.orderBy ? this.orderBy : 'create_at desc'
    };
    this.getQa(this.request);
  }

  onOpenQaDetail(qa: Qa): void {
    this.dialog.open(QaDetailComponent, {
      width: '800px',
      height: '90vh',
      data: qa
    });
  }

  onEdit(id): void {
    this.dialog.open(QaSaveComponent, {
      width: '800px',
      height: '90vh',
      data: id
    });
    // this.router.navigate(['/qa-save', id]);
  }

  onDelete(id): void {
    this.dialog.open(QaDeleteDialogComponent, {
      width: '300px',
      data: id
    });
  }

  reload(): void {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigateByUrl('/qa-list');
    });
  }
}
