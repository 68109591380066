<div class="example-container" [class.example-is-mobile]="mobileQuery.matches">
  <mat-toolbar color="primary" class="example-toolbar">
    <button *ngIf="isLogin" mat-icon-button (click)="snav.toggle()"><mat-icon>menu_open</mat-icon></button>
    <h1 class="example-app-name pointer" routerLink="/">Bảo vệ chính pháp</h1>
    <span class="example-spacer"></span>
    <button *ngIf="isLogin" mat-icon-button [matMenuTriggerFor]="user" class="example-icon favorite-icon"> <mat-icon>account_box</mat-icon> </button>
    <mat-menu #user="matMenu">
      <button mat-menu-item>{{currentUser?.email}}</button>
      <button mat-menu-item (click)="logout()">Đăng xuất</button>
    </mat-menu>
  </mat-toolbar>

  <mat-sidenav-container class="example-sidenav-container"
                         [style.marginTop.px]="mobileQuery.matches ? 56 : 0">
    <mat-sidenav opened="true" #snav [mode]="mobileQuery.matches ? 'over' : 'side'"
                 [fixedInViewport]="mobileQuery.matches" fixedTopGap="56">
      <div *ngIf="isLogin">
        <button mat-button [matMenuTriggerFor]="post" class="button-left"><mat-icon>article</mat-icon> Bài viết</button>
        <mat-menu class="ml-20" #post="matMenu">
          <button mat-menu-item routerLink="/post-list">Tất cả bài viết</button>
          <button mat-menu-item routerLink="/post-create">Thêm bài mới</button>
        </mat-menu> <br>
        <button class="button-left" mat-button routerLink="/media"><mat-icon>perm_media</mat-icon> Ảnh</button> <br>
        <button class="button-left" mat-button routerLink="/category" *ngIf="isAdmin" ><mat-icon>category</mat-icon> Danh mục/Menu</button> <br *ngIf="isAdmin">
        <button class="button-left" mat-button routerLink="/user" *ngIf="isAdmin"><mat-icon>account_box</mat-icon> User</button> <br *ngIf="isAdmin">
        <button class="button-left" mat-button routerLink="/comment-list"><mat-icon>comment</mat-icon><span matBadge="{{countCommentUnApprove}}" matBadgeOverlap="false"> Bình luận</span></button> <br>
        <button class="button-left" mat-button routerLink="/qa-list"><mat-icon>question_answer</mat-icon><span matBadge="{{countQaNotReply}}" matBadgeOverlap="false">  Hỏi đáp</span></button> <br>
        <button class="button-left" mat-button routerLink="/template" *ngIf="isAdmin"><mat-icon>view_carousel</mat-icon> Template</button> <br *ngIf="isAdmin">
        <button class="button-left" mat-button routerLink="/setting" *ngIf="isAdmin"><mat-icon>build</mat-icon> Cài đặt</button> <br *ngIf="isAdmin">
      </div>
    </mat-sidenav>
    <mat-sidenav-content style="padding: 5px 15px 30px 15px">
      <router-outlet></router-outlet>
      <ngx-loading [show]="loading" [config]="{fullScreenBackdrop : true}"></ngx-loading>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
