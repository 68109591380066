<div class="postUpdateDialog">
  <button class="close" mat-button (click)="onNoClick()" style="color: white;font-family: cursive;font-weight: bold;">X</button>
  <form [formGroup]="saveForm" (ngSubmit)="onSubmit()">
    <mat-grid-list cols="12" rowHeight="30px">
      <mat-grid-tile [colspan]="9" [rowspan]="3">
        <mat-form-field appearance="fill">
          <mat-label>Tiêu đề</mat-label>
          <input required matInput formControlName="title" [errorStateMatcher]="matcher" maxlength="200">
          <mat-error *ngIf="saveForm.controls.title.hasError('required')">
            Cần nhập vào tiêu đề
          </mat-error>
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="3" [rowspan]="30">
        <div class="top-10 left-15">
          <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon>label_important</mat-icon>
              </mat-panel-title>
              <mat-panel-description>
                Tùy chọn bài đăng
              </mat-panel-description>
            </mat-expansion-panel-header>
            <mat-form-field appearance="fill">
              <mat-label>Kiểu bài viết</mat-label>
              <mat-select formControlName="postType" required>
                <mat-option value="text">Text</mat-option>
                <mat-option value="video">Video</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Thể loại</mat-label>
              <mat-select formControlName="categoryId" required>
                <mat-option *ngFor="let cate of categories" value="{{cate?.id}}" (click)="getCategorySubs(cate)">{{cate?.name}}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill" *ngIf="categorySubs && categorySubs.length && categorySubs.length > 0">
              <mat-label>Thể loại con</mat-label>
              <mat-select formControlName="categorySubId">
                <mat-option *ngFor="let cateSub of categorySubs" value="{{cateSub?.id}}">{{cateSub?.name}}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="example-chip-list" appearance="fill">
              <mat-label>Tag</mat-label>
              <mat-chip-list #chipList aria-label="Fruit selection">
                <mat-chip
                  *ngFor="let item of tagsFixedRequest"
                  [selectable]="true"
                  [removable]="true"
                  (removed)="remove(item)">
                  {{item}}
                  <mat-icon matChipRemove *ngIf="true">cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Gắn tag cho bài viết..."
                  #tagsInput
                  [formControl]="tagControl"
                  [matAutocomplete]="auto"
                  [matChipInputFor]="chipList"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  (matChipInputTokenEnd)="add($event)">
              </mat-chip-list>
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let item of filteredOptions | async" [value]="item" (onSelectionChange)="updateTagSelection(item)">
                  {{item}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <mat-checkbox formControlName="isPublish" class="example-margin">Chế độ công khai (public)
            </mat-checkbox>
            <br>
            <mat-checkbox formControlName="isFeature" class="example-margin">Đánh dấu là Tin nổi bật</mat-checkbox>
            <mat-action-row>
              <button mat-raised-button color="primary">Cập nhật</button>
              <button mat-raised-button color="primary" type="button" (click)="onNoClick()">Đóng</button>
            </mat-action-row>
          </mat-expansion-panel>
        </div>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="9" [rowspan]="5">
        <mat-form-field appearance="fill">
          <mat-label>Tóm tắt</mat-label>
          <textarea required matInput placeholder="" matTextareaAutosize matAutosizeMinRows="4" matAutosizeMaxRows="4"
                    formControlName="description" [(ngModel)]="description" maxlength="200"></textarea>
          <mat-hint align="end">{{description?.length || 0}}/200 ký tự tối đa.</mat-hint>
          <mat-error *ngIf="saveForm.controls.description.hasError('required')">
            Cần nhập vào tóm tắt
          </mat-error>
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="9" [rowspan]="5">
        <div class="left-0 top-0">
          Upload thumbnail: <input type="file" id="fileThumbnail" name="fileThumbnail" formControlName="fileThumbnail" (change)="uploadThumbnail($event.target.files)"/>
          <img style="width: auto; height: 55px; margin-bottom: -28px" src="{{thumbnail}}">
        </div>
        <mat-form-field>
          <mat-label>Thumbnail URL</mat-label>
          <input style="font-size: 14px" matInput [(ngModel)]="thumbnail" [ngModelOptions]="{standalone: true}">
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="9" [rowspan]="1" style="display: inline-flex">
        <mat-form-field>
          <mat-label>Chèn URL ảnh tới vị trí trong bài viết</mat-label>
          <input style="font-size: 14px" matInput [(ngModel)]="urlImageEmbed" [ngModelOptions]="{standalone: true}" placeholder="1.Thêm URL ảnh vào đây; 2.Chọn vị trí cần chèn ở bài viết; 3.Sử dụng nút Chèn">
        </mat-form-field>
        <button style="margin-right: 5px; margin-left: 5px" type="button" mat-button color="primary" (click)="insertImageUrl()">CHÈN</button>
        <button style="margin-right: 8px;padding-right: 30px;" type="button" mat-button color="primary" (click)="openMediaListDialog()">THƯ VIỆN ẢNH</button>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="9" [rowspan]="20">
        <div class="top-10">
          <quill-editor [styles]="{height: '500px'}"
                        [modules]="modules"
                        (onEditorCreated)="created($event)"
                        (onEditorChanged)="changedEditor($event)"
                        [(ngModel)]="content"
                        spellcheck="false"
                        placeholder="Nhập nội dung bài viết ở đây"
                        formControlName="content"
          ></quill-editor>
          <mat-hint style="font-size: 12px" align="end">{{(content?.length - 7) > 0 ? content?.length - 7 : 0}} ký tự
          </mat-hint>
          <mat-error style="font-size: 12px" *ngIf="isContent == false">
            Cần nhập vào nội dung bài viết
          </mat-error>
          <input type="file" style="display: none" id="fileInputField" name="fileInputField"/>
        </div>
      </mat-grid-tile>
    </mat-grid-list>
  </form>
</div>

<!--  <button (click)="updateContentWhenSave()" mat-raised-button color="primary">Primary</button>-->

<!--  <div><code>{{ content }}</code></div>-->
<!--  <hr>-->
<!--  <div><code>{{ contentSave }}</code></div>-->
<!--  <hr>-->
<!--  <div [innerHTML]="safeHtml"></div>-->
