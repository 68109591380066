import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatSliderModule} from '@angular/material/slider';
import {HomeComponent} from './modules/home/home.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import {LayoutComponent} from './modules/layout/layout.component';
import {NotFoundComponent} from './modules/not-found/not-found.component';
import {RouterModule, Routes} from '@angular/router';
import {QuillModule} from 'ngx-quill';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {HeaderService} from './shared/services/header.service';
import {SearchService} from './shared/services/search.service';
import {HomeService} from './shared/services/home.service';
import {CategoryService} from './shared/services/category.service';
import {PostService} from './shared/services/post.service';
import 'rxjs-compat';
import {PostCreateComponent} from './modules/post/post-create/post-create.component';
import {ToastrModule} from 'ngx-toastr';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatRadioModule} from '@angular/material/radio';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatCardModule} from '@angular/material/card';
import {MatDividerModule} from '@angular/material/divider';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatIconModule} from '@angular/material/icon';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {LoginComponent} from './modules/login/login.component';
import {AuthGuard} from './shared/helper/auth.guard';
import {JwtInterceptor} from './shared/helper/jwt.interceptor';
import {ErrorInterceptor} from './shared/helper/error.interceptor';
import {NgxLoadingModule} from 'ngx-loading';
import {LoadingService} from './shared/services/loading.service';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {PostUpdateComponent} from './modules/post/post-update/post-update.component';
import {PostListComponent} from './modules/post/post-list/post-list.component';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {PostDeleteDialogComponent} from './modules/post/post-delete-dialog/post-delete-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {QaDetailComponent} from './modules/qa/qa-detail/qa-detail.component';
import {QaListComponent} from './modules/qa/qa-list.component';
import {QaSaveComponent} from './modules/qa/qa-save/qa-save.component';
import {QaDeleteDialogComponent} from './modules/qa/qa-delete-dialog/qa-delete-dialog.component';
import {CommentListComponent} from './modules/comment/comment-list/comment-list.component';
import {CommentSaveDialogComponent} from './modules/comment/comment-save/comment-save-dialog.component';
import {CommentDeleteDialogComponent} from './modules/comment/comment-delete-dialog/comment-delete-dialog.component';
import {CommentDetailDialogComponent} from './modules/comment/comment-detail-dialog/comment-detail-dialog.component';
import {CategoryComponent} from './modules/category/category.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {CategoryDeleteDialogComponent} from './modules/category/category-delete-dialog/category-delete-dialog.component';
import {SettingComponent} from './modules/setting/setting.component';
import {UserComponent} from './modules/user/user.component';
import {MAT_COLOR_FORMATS, NGX_MAT_COLOR_FORMATS, NgxMatColorPickerModule} from '@angular-material-components/color-picker';
import {UserDeleteDialogComponent} from './modules/user/user-delete-dialog/user-delete-dialog.component';
import {UserSaveDialogComponent} from './modules/user/user-save-dialog/user-save-dialog.component';
import {UserCreateDialogComponent} from './modules/user/user-create-dialog/user-create-dialog.component';
import {TemplateComponent} from './modules/template/template.component';
import {CategoryCreateDialogComponent} from './modules/category/category-create-dialog/category-create-dialog.component';
import {UserType} from './shared/models/enum/user-type';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatChipsModule} from '@angular/material/chips';
import { MediaListComponent } from './modules/media/media-list/media-list.component';
import { MediaDeleteDialogComponent } from './modules/media/media-delete-dialog/media-delete-dialog.component';
import {MatBadgeModule} from '@angular/material/badge';

const routes: Routes = [
  {path: '', component: PostListComponent, canActivate: [AuthGuard]},
  {path: 'login', component: LoginComponent},
  {
    path: 'post-create',
    component: PostCreateComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'post-update/:id',
    component: PostUpdateComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'post-list',
    component: PostListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'qa-list',
    component: QaListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'qa-save/:id',
    component: QaSaveComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'comment-list',
    component: CommentListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'media',
    component: MediaListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'comment-save/:id',
    component: CommentListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'category',
    component: CategoryComponent,
    canActivate: [AuthGuard],
    data: {roles: [UserType.ADMIN]}
  },
  {
    path: 'setting',
    component: SettingComponent,
    canActivate: [AuthGuard],
    data: {roles: [UserType.ADMIN]}
  },
  {
    path: 'user',
    component: UserComponent,
    canActivate: [AuthGuard],
    data: {roles: [UserType.ADMIN]}
  },
  {
    path: 'template',
    component: TemplateComponent,
    canActivate: [AuthGuard],
    data: {roles: [UserType.ADMIN]}
  },
  {
    path: '**',
    component: NotFoundComponent,
    canActivate: [AuthGuard]
  },
];

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LayoutComponent,
    NotFoundComponent,
    PostCreateComponent,
    LoginComponent,
    PostUpdateComponent,
    PostListComponent,
    PostDeleteDialogComponent,
    QaDetailComponent,
    QaListComponent,
    QaSaveComponent,
    QaDeleteDialogComponent,
    CommentListComponent,
    CommentSaveDialogComponent,
    CommentDeleteDialogComponent,
    CommentDetailDialogComponent,
    CategoryComponent,
    CategoryDeleteDialogComponent,
    SettingComponent,
    UserComponent,
    UserDeleteDialogComponent,
    UserSaveDialogComponent,
    UserCreateDialogComponent,
    TemplateComponent,
    CategoryCreateDialogComponent,
    MediaListComponent,
    MediaDeleteDialogComponent
  ],
  imports: [
    RouterModule.forRoot(routes),
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSliderModule,
    MatSidenavModule,
    CommonModule,
    QuillModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    ToastrModule.forRoot(),
    HttpClientModule,
    MatGridListModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatRadioModule,
    MatCheckboxModule,
    MatCardModule,
    MatDividerModule,
    MatExpansionModule,
    MatIconModule,
    MatToolbarModule,
    MatListModule,
    MatMenuModule,
    NgxLoadingModule,
    MatButtonToggleModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    MatDialogModule,
    DragDropModule,
    NgxMatColorPickerModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatBadgeModule
  ],
  providers: [
    HttpClientModule, HeaderService, SearchService, HomeService, CategoryService, PostService, LoadingService,
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {provide: MAT_COLOR_FORMATS, useValue: NGX_MAT_COLOR_FORMATS}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
