import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Path} from '../common/path';
import {HttpClient} from '@angular/common/http';
import 'rxjs-compat/add/operator/map';
import {Result} from '../models/response/result';
import {CommentRequest} from '../models/request/CommentRequest';
import {Comment} from '../models/response/comment';
import {PagingRequest} from '../models/request/paging-request';
import {Media} from '../models/response/media';

@Injectable({
  providedIn: 'root'
})
export class MediaService {

  constructor(private http: HttpClient) {
  }

  uploadImage(fileToUpload: File): Observable<Result<any>> {
    const endpoint = Path.IMAGE_UPLOAD;
    const formData: FormData = new FormData();
    formData.append('image_file', fileToUpload, fileToUpload.name);
    return this.http.post(endpoint, formData)
      .map((result: Result<any>) => {
        return result;
      }, error => {
        return error;
      });
  }

  uploadVideo(fileToUpload: File): Observable<boolean> {
    const endpoint = Path.VIDEO_UPLOAD;
    const formData: FormData = new FormData();
    formData.append('video_file', fileToUpload, fileToUpload.name);
    return this.http
      .post(endpoint, formData)
      .map(() => {
        return true;
      }, error => {
        return error;
      });
  }

  getImages(request: PagingRequest): Observable<Result<Media[]>> {
    return this.http.get<any>(Path.MEDIA + '?limit=' + request.limit +
      '&page=' + request.page + (request.keyword ? ('&keyword=' + request.keyword) : '') +
      (request.orderby ? ('&orderby=' + request.orderby) : '')
      , {}).map(
      result => {
        return result;
      }, error => {
        return error;
      }
    );
  }

  delete(id: number): Observable<Result<any>> {
    return this.http.post(Path.DELETE_MEDIA + '/\{id\}?id=' + id, id).map((result: Result<any>) => {
      return result;
    }, error => {
      return error;
    });
  }
}
