import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import Quill from 'quill';
import VideoResize from 'quill-video-resize-module';
import ImageResize from 'quill-image-resize-module';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {ToastrService} from 'ngx-toastr';
import {FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {PostService} from '../../../shared/services/post.service';
import {PostRequest} from '../../../shared/models/request/post-request';
import {CategoryService} from '../../../shared/services/category.service';
import {Category} from '../../../shared/models/response/category';
import {EditorChangeContent, EditorChangeSelection} from 'ngx-quill';
import {Path} from '../../../shared/common/path';
import {MediaService} from '../../../shared/services/media.service';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatChipInputEvent} from '@angular/material/chips';
import {MatAutocomplete, MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MediaListComponent} from '../../media/media-list/media-list.component';
import {MatDialog} from '@angular/material/dialog';
import {CategorySub} from '../../../shared/models/response/categorySub';

Quill.register('modules/imageResize', ImageResize);
Quill.register('modules/videoResize', VideoResize);

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-post-create',
  templateUrl: './post-create.component.html',
  styleUrls: ['./post-create.component.css']
})
export class PostCreateComponent implements OnInit {
  blured = false;
  focused = false;
  content: string;
  description: string;
  contentSave: any;
  safeHtml: SafeHtml;
  modules: any;
  editor: any;
  quill: any;
  maxUploadFileSize = 1000000;
  saveForm: FormGroup;
  host = Path.HOST;

  categories: Category[];
  categorySubs: CategorySub[];
  isContent = true;
  fileThumbnail: File = null;
  thumbnail: string;
  pathUploadapi: string;
  urlImageEmbed = '';

  matcher = new MyErrorStateMatcher();

  tagsFixed: string[];
  tagsFixedRequest: string[] = [];
  tagControl = new FormControl();
  filteredOptions: Observable<string[]>;
  @ViewChild('tagsInput') tagsInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  isDisableSaveButton = false;

  // for upload to editor
  imageHandler = (image, callback) => {
    const input = document.getElementById('fileInputField') as HTMLInputElement;
    document.getElementById('fileInputField').onchange = () => {
      let file: File;
      file = input.files[0];
      // file type is only image.
      if (/^image\//.test(file.type)) {
        if (file.size > this.maxUploadFileSize) {
          this.toastr.warning('Ảnh cần nhỏ hơn 1MB', null, {timeOut: 12000});
        } else {
          const reader = new FileReader();
          reader.onload = () => {
            const range = this.editor.getSelection();
            this.mediaService.uploadImage(file).subscribe(res => {
                const img = '<img src="' + Path.HOST + res.data.path + '" /> ';
                this.editor.clipboard.dangerouslyPasteHTML(range.index, img);
              }, (err) => this.toastr.warning('Ảnh upload đang gặp sự cố', null, {timeOut: 12000})
            );
          };
          reader.readAsDataURL(file);
        }
      } else {
        this.toastr.warning('Bạn chỉ có thể upload ảnh', null, {timeOut: 12000});
      }
    };

    input.click();
    // const range = this.editor.getSelection();
    // const img = '<a href="https://image.flaticon.com/icons/png/128/126/126477.png" data-lightbox="image-1" data-title="My caption"> <div>
    // <img src="https://image.flaticon.com/icons/png/128/126/126477.png" height="50"/> </div> </a>';
    // this.editor.clipboard.dangerouslyPasteHTML(range.index, img);
  };

  constructor(private sanitizer: DomSanitizer,
              private toastr: ToastrService,
              private formBuilder: FormBuilder,
              private httpClient: HttpClient,
              private postService: PostService,
              private categoryService: CategoryService,
              private mediaService: MediaService,
              private dialog: MatDialog) {
  }

  //
  ngOnInit(): void {
    this.pathUploadapi = Path.IMAGE_UPLOAD;
    this.getCategory();
    this.getTag();
    this.modules = {
      toolbar: {
        container: [
          ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
          ['blockquote', 'code-block'],
          [{header: 1}, {header: 2}],               // custom button values
          [{list: 'ordered'}, {list: 'bullet'}],
          [{script: 'sub'}, {script: 'super'}],      // superscript/subscript
          [{indent: '-1'}, {indent: '+1'}],          // outdent/indent
          [{direction: 'rtl'}],                         // text direction
          [{size: ['small', false, 'large', 'huge']}],  // custom dropdown
          [{header: [1, 2, 3, 4, 5, 6, false]}],
          [{color: new Array<any>()}, {background: new Array<any>()}],          // dropdown with defaults from theme
          [{font: new Array<any>()}],
          [{align: new Array<any>()}],

          ['clean'],                                         // remove formatting button

          ['link', 'image', 'video']                         // link and image, video
        ]
      },
      imageResize: {},
      videoResize: {}
    };

    this.saveForm = this.formBuilder.group({
      title: ['', [
        Validators.required,
        Validators.maxLength(200)
      ]],
      categoryId: ['', Validators.required],
      categorySubId: [''],
      postType: ['', Validators.required],
      description: ['', [
        Validators.required,
        Validators.maxLength(200)
      ]],
      isPublish: [false],
      isFeature: [false],
      content: ['', Validators.required],
      fileThumbnail: [''],
    });
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our item
    if ((value || '').trim()) {
      this.tagsFixedRequest.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.tagControl.setValue(null);
  }

  remove(item: string): void {
    const index = this.tagsFixedRequest.indexOf(item);

    if (index >= 0) {
      this.tagsFixedRequest.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    // this.tagsFixedRequest.push({description: "", id: null, templateId: null, type: "", name: event.option.viewValue});
    // this.tagsInput.nativeElement.value = '';
    // this.tagControl.setValue(null);
  }

  updateTagSelection(name: string): void {
    this.tagsFixedRequest.push(name);
    this.tagsInput.nativeElement.value = '';
    this.tagControl.setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.tagsFixed.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }

  onSubmit(): void {
    this.isDisableSaveButton = true;
    // console.warn('----------', this.tagControl);
    // console.log('---', this.tagsFixedRequest);
    const postRequest = new PostRequest();
    const value = this.saveForm.value;
    postRequest.title = value.title;
    postRequest.description = value.description;
    if (!this.content) {
      this.isContent = false;
      this.isDisableSaveButton = false;
      return;
    }
    postRequest.content = this.updateContentBeforeSave(value.content);
    postRequest.categoryId = value.categoryId;
    postRequest.categorySubId = value.categorySubId;
    postRequest.postType = value.postType;
    postRequest.isPublish = value.isPublish;
    postRequest.isFeature = value.isFeature;
    postRequest.thumbnail = this.thumbnail ? this.thumbnail.replace(this.host, '') : '';
    postRequest.tagPosts = this.tagsFixedRequest;

    if (!this.content) {
      this.isContent = false;
      this.isDisableSaveButton = false;
      return;
    }
    if (this.saveForm.invalid) {
      this.isDisableSaveButton = false;
      return;
    }

    this.postService.savePost(postRequest).subscribe((result) => {
      if (result) {
        this.toastr.success('Tạo bài viết thành công!', null, {timeOut: 12000});
        setTimeout(() => {
          this.revert();
          this.toastr.success('Bạn có thể tiếp tục đăng bài viết mới', null, {timeOut: 12000});
          this.isDisableSaveButton = false;
        }, 4000);
      }
    }, (e) => {
      this.isDisableSaveButton = false;
      console.log(e);
    });
  }

  revert(): void {
    this.saveForm.reset();
    this.tagsFixedRequest = [];
    this.thumbnail = null;
  }

  getCategory(): void {
    this.categoryService.getCategoryAll().subscribe((result) => {
      if (result) {
        this.categories = result.filter(item => item.type === 'POST');
      }
    }, (e) => {
      console.log(e);
    });
  }

  getCategorySubs(cate: Category): void {
      this.categorySubs = cate.categorySubs;
  }

  getTag() {
    this.postService.getTag().subscribe(result => {
      if (result) {
        this.tagsFixed = result.data;

        // handel autocomplete
        this.filteredOptions = this.tagControl.valueChanges.pipe(
          startWith(null),
          map((item: string | null) => item ? this._filter(item) : this.tagsFixed.slice())
        );
        console.log(this.filteredOptions);
      }
    });
  }

  updateContentBeforeSave(content: string): string {
    // this.toastr.success('Offer code is created successfully');
    this.contentSave = content;

    if (!content.includes('https://www.youtube.com')) {
      // replace <video> tag if amazon s3
      this.contentSave = content.replace(/<iframe/g, '<div style="position: relative; padding-bottom: 56.25%; ' +
        'height: 0; overflow: hidden; box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);"><video controls preload="none" style="' +
        'position: absolute; top: 0; left: 0; width: 100%; height: 100%;"');
      this.contentSave = this.contentSave.replace(/iframe>/g, 'video></div>');
    } else {
      // replace <iframe> tag if youtube
      this.contentSave = this.contentSave.replace(/<iframe class="ql-video"/g, '<iframe width="800px" height="440px"');
      this.contentSave = this.contentSave.replace(/iframe>/g, 'iframe>');
    }
    this.safeHtml = this.sanitizer.bypassSecurityTrustHtml(this.contentSave);
    return this.contentSave;
  }

  changedEditor(event: EditorChangeContent | EditorChangeSelection): void {
    if (event) {
      this.isContent = true;
    }
  }

  created(event: any): void {
    this.editor = event;
    const toolbar = event.getModule('toolbar');
    toolbar.addHandler('image', this.imageHandler);
    // this.content = '<iframe class="ql-video" frameborder="0" allowfullscreen="true"
    // src="https://bvcp.s3.amazonaws.com/video/test+(1).mp4"></iframe>';
  }

  insertImageUrl() {
    const range = this.editor.getSelection();
    const img = '<img src="' + this.urlImageEmbed + '" /> ';
    this.editor.clipboard.dangerouslyPasteHTML(range.index, img);
  }

  openMediaListDialog() {
    this.dialog.open(MediaListComponent, {
      height: '90vh'
    });
  }

  // onFileSelect(event) {
  //   if (event.target.files.length > 0) {
  //     const file = event.target.files[0];
  //     this.uploadForm.get('profile').setValue(file);
  //   }
  // }

  // handleFileInput(files: FileList) {
  //   this.fileToUpload = files.item(0);
  // }
  uploadThumbnail(files: FileList): void {
    const fileUpload = files.item(0);
    this.mediaService.uploadImage(fileUpload).subscribe(res => {
        this.thumbnail = this.host + res.data.path;
      }, (err) => this.toastr.warning('Ảnh thumbnail gặp sự cố upload', null, {timeOut: 12000})
    );
  }

  // focus($event): void {
  // console.log('focus', $event);
  // this.focused = true;
  // this.blured = false;
  // }

  // blur($event): void {
  // console.log('blur', $event);
  // this.focused = false;
  // this.blured = true;
  // }
}
