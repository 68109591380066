import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {Post} from '../../../shared/models/response/post';
import {CategoryService} from '../../../shared/services/category.service';
import {Category} from '../../../shared/models/response/category';
import {CategoryRequest} from '../../../shared/models/request/category-request';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {PostDeleteDialogComponent} from '../post-delete-dialog/post-delete-dialog.component';
import {Path} from '../../../shared/common/path';
import {PostUpdateComponent} from '../post-update/post-update.component';

@Component({
  selector: 'app-post-list',
  templateUrl: './post-list.component.html',
  styleUrls: ['./post-list.component.css']
})
export class PostListComponent implements OnInit, AfterViewInit {

  categories: Category[];
  displayedColumns: string[] = ['id', 'title', 'category_name', 'category_sub_name', 'author_name', 'commentCount', 'view_counts', 'is_feature', 'is_publish', 'post_type', 'create_at', 'publishDate', 'update_at',
    'edit', 'delete'];
  dataSource: MatTableDataSource<Post>;
  pageSize: number;
  pageIndex: number;
  orderBy: string;
  length: number;
  pageSizeOptions: number[] = [10, 25, 100];
  categoryId: number;
  categoryIdDefault: number;
  categoryRequest: CategoryRequest;
  webHost = Path.WEB_HOST;
  keyword: string;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private categoryService: CategoryService,
              private router: Router,
              public dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.getCategories();
  }

  getCategories(): void {
    this.categoryService.getCategoryAll().subscribe((result) => {
      if (result) {
        this.categories = result.filter(item => item.type === 'POST');
        this.categoryIdDefault = 0;
        this.onSelectCategoryChange();
      }
    }, (e) => {
      console.log(e);
    });
  }

  loadInitPosts(): void {
    this.categoryRequest = {
      id: this.categoryId ? this.categoryId : this.categoryIdDefault,
      limit: 10,
      page: 0,
      keyword: this.keyword,
      orderby: 'create_at desc'
    };
    this.getPosts(this.categoryRequest);
  }

  getPosts(request: CategoryRequest): void {
    this.categoryService.getPosts(request).subscribe(
      (result) => {
        this.length = result.metadata.total;
        this.dataSource = new MatTableDataSource(result.data);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  ngAfterViewInit(): void {
  }

  onPageChange(pageEvent): void {
    this.pageSize = pageEvent.pageSize;
    this.pageIndex = pageEvent.pageSize;
    this.categoryRequest = {
      id: this.categoryId ? this.categoryId : this.categoryIdDefault,
      limit: pageEvent.pageSize,
      page: pageEvent.pageIndex,
      keyword: this.keyword,
      orderby: this.orderBy ? this.orderBy : 'create_at desc'
    };
    this.getPosts(this.categoryRequest);
  }

  onSelectCategoryChange(categoryId?): void {
    if (categoryId) {
      this.categoryId = Number(categoryId);
    }
    this.loadInitPosts();
  }

  onEdit(postId): void {
    // this.router.navigate(['/post-update', id]);
    this.dialog.open(PostUpdateComponent, {
      width: '100%',
      height: '100vh',
      maxWidth: '300vh',
      data: postId,
      panelClass: 'my-dialog'
    });
  }

  onDelete(id): void {
    this.dialog.open(PostDeleteDialogComponent, {
      width: '300px',
      data: {id: id}
    });
  }

  sortData(sortEvent): void {
    if (sortEvent.direction === '') {
      this.orderBy = 'create_at desc';
    } else {
      this.orderBy = sortEvent.active + ' ' + sortEvent.direction;
    }
    this.categoryRequest = {
      id: this.categoryId ? this.categoryId : this.categoryIdDefault,
      limit: this.paginator.pageSize,
      page: this.paginator.pageIndex,
      keyword: this.keyword,
      orderby: this.orderBy
    }
    ;
    this.getPosts(this.categoryRequest);
  }

  onSearch(): void {
    this.categoryRequest = {
      id: this.categoryId ? this.categoryId : this.categoryIdDefault,
      limit: 10,
      page: 0,
      keyword: this.keyword,
      orderby: this.orderBy ? this.orderBy : 'create_at desc'
    };
    this.getPosts(this.categoryRequest);
  }

  openCreatePost() {
    this.router.navigateByUrl('/post-create');
  }

  reload(): void {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigateByUrl('/post-list');
    });
  }
}
