import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {PostService} from '../../../shared/services/post.service';
import {Category} from '../../../shared/models/response/category';

export interface DialogDeleteData {
  id: number;
  title: string;
}

@Component({
  selector: 'app-post-delete-dialog',
  templateUrl: './post-delete-dialog.component.html',
  styleUrls: ['./post-delete-dialog.component.css']
})
export class PostDeleteDialogComponent implements OnInit {

  constructor(
    private postService: PostService,
    public dialogRef: MatDialogRef<PostDeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Category) {
  }

  ngOnInit(): void {
  }

  onDelete(): void {
    this.postService.delete(this.data.id).subscribe(
      (result) => {
        location.reload(true);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
